import moment from "moment";
import { DateFormats } from "../Constants/DateFormats";
import { DateToBeginApplication } from "../Constants/DateToBeginApplication";

export const AllowAccessToApplication = () => {
  const allowDate = moment(DateToBeginApplication).format(DateFormats.primary);
  const now = moment().format(DateFormats.primary);

  return now > allowDate;
};
