
import { USER_FAILURE, USER_REQUEST, USER_SUCCESS } from '../../Constants/UserConstants'

const userReducer = (state = 0, action: { type: any; data: any; }) => {
  switch (action.type) {
    case USER_SUCCESS:
      return { success: action.data }
    case USER_REQUEST:
      return { loading: true }
    case USER_FAILURE:
      return { error: action.data }
    default:
      return state
  }
}

export default userReducer
