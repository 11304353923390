/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import React, { Fragment, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import Footer from '../../../../../Layout/Footer'
import { RegisterHeader } from '../../../Components/Index'
import "./PrintAptitudeSlip.scss"
import applicantsService from '../../../../../Services/applicantsService'
import { Table } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import toast, { Toaster } from 'react-hot-toast'

interface formValues {
  nin: string;
}

const PrintAptitudeSlip = () => {
  // exam-slip
  const { handleSubmit, register, formState: { errors } } = useForm<formValues>()
  const [hideButton, setHiddenButton] = useState<boolean>(false)
  const [aptitudeData, setAptitudeData] = useState<any>()
  const [admissionInfo, setAdmissionInfo] = useState<string | any>()
  const [htmlError, setHtmlError] = useState<any>()
  const [authImg, setAuthImg] = useState<any>()
  const onSubmit = handleSubmit((data: { [x: string]: any }) => processData(data))
  // const state = undefined
  // console.log("HI", selector)
  useEffect(() => {
    const dateEx: string | null = localStorage.getItem("MENU_COMMAND")
    const dataJson = dateEx !== null && JSON.parse(dateEx)
    const dateJson = dataJson.data.admissionYear
    setAdmissionInfo(dateJson)
  }, [])
  const processData = async (data: { [x: string]: any; }) => {
    try {
      const res: any = await applicantsService.aptitudeQuery(data.nin)
      const resData = res?.data?.dataset
      // console.log(res)
      setAuthImg(res?.data)
      setAptitudeData(resData)
    } catch (error: any) {
      if (error.response.data.display === 'exception') {
        toast.error(error?.response.data.message)
      } else {
        const dataSplit = error?.response.data.message
        const done = dataSplit.split(",")
        const secondDone = done[1]
        const secondSplit = secondDone.split(":")
        setHtmlError({ done, secondSplit })
      }
    }
  }
  return (
    <React.Fragment>
      <RegisterHeader />
      {aptitudeData === undefined && <>
        {htmlError === undefined
          ? <div className="mb-5 h-100 print-aptitude-slip">
            <div className='container pt-4'>
              <h2>Print/Reprint General-Duty Slip</h2>
            </div>
            <div className="py-5 px-2  d-flex align-items-center">
              <div className="container">
                <div className="card border">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-6 offset-md-3">
                        <form onSubmit={onSubmit}>
                          <div className="form-group">
                            <label> National Identification No (NIN)</label>
                            <input className="form-control" {...register("nin", { required: 'field cannot be empty' })}></input>
                            <div className="register--error text-danger">
                              {errors.nin && errors.nin.message}
                            </div>
                          </div>
                          <div className="form-group text-center">
                            <button className="btn btn-warning text-dark px-5 font-weight-bold">Print Slip</button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          : <div>
            <div className='pSchedule'>
              <div className="cbt-details my-5">
                <div className="">
                  <div className="container">
                    <div className="row">
                      <div className="col-md-8 offset-md-2">
                        <div className="card bg-white p-2">
                          <div className="table-responsive overflow-hidden">
                            <Table bordered className='rounded-5'>
                              <tbody>
                                <tr>
                                  <td colSpan={2} className="p-0">
                                    <div className="card-header text-center text-dark card-status-header px-5">
                                      <div className='row'>
                                        <div className='col'>
                                          <div className='row'>
                                            <div className="col d-flex justify-content-end">
                                              <img className="img-responsive rounded-circle" width='100' height='100' src='psc_logo.png' />
                                            </div>
                                            <div className="col d-flex justify-content-start">
                                              <img className="img-responsive" width='100' height='100' src='police_logo.png' />
                                            </div>
                                          </div>
                                        </div>

                                      </div>
                                      <div className='mb-0 pb-0 pt-4'>
                                        <h1 className="text-light font-weight-bolder cbt-title fs-1 p-0"> {admissionInfo || "2022"} Nigeria Police Force Recruitment</h1></div>

                                    </div>
                                  </td>
                                </tr>

                                <tr>
                                  <td colSpan={2}>
                                    <div className='mt-0 pt-0'><h5 className="text-dark text-center pt-2 ">{admissionInfo || "2022"} Aptitude/CBT Schedule Slip</h5></div>
                                    <div className="d-flex justify-content-center py-4 row">
                                      <div className='col align-self-center'>
                                        <div className='fs-4 fw-bold text-center'><strong>{ htmlError.done[0] }</strong></div>
                                        <div className='fs-4 mt-2 text-center'><span className='text-danger fw-bolder'><strong>Error:</strong> </span>{htmlError.secondSplit[1]}</div>
                                        <div className="text-secondary fw-bolder"></div>
                                      </div>
                                    </div>
                                  </td>
                                </tr>

                                <tr>
                                  <td className="text-center close-col" colSpan={2}>
                                    <div className="pcolor1 px-5">
                                      <div className="section-color-tage1"></div>
                                      <div className="section-color-tage2"></div>
                                      <div className="section-color-tage3"></div>
                                      <div className="section-color-tage4"></div>
                                      <div className="section-color-tage5"></div>
                                    </div>
                                  </td>
                                </tr>

                              </tbody>
                            </Table>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='pt-3 pb-5 justify-content-center d-flex'>
                    <button className='btn btn-primary' onClick={() => { window.location.reload() }}>Close</button>
                  </div>
                </div>

              </div>
            </div>
          </div>
        }
      </>
      }
      {
        aptitudeData !== undefined && <div className='pSchedule'>
          <div className="cbt-details my-5">
            <div className="">
              <div className="container">
                <div className="row">
                  <div className="col-md-8 offset-md-2">
                    <div className="card bg-white p-2">
                      <div className="table-responsive overflow-hidden">
                        <Table bordered className='rounded-5'>
                          <tbody>
                            <tr>
                              <td colSpan={2} className="p-0">
                                <div className="card-header text-center text-dark card-status-header px-5">
                                  <div className='row'>
                                    <div className='col'>
                                      <div className='row'>
                                        <div className="col d-flex justify-content-end">
                                          <img className="img-responsive rounded-circle" width='100' height='100' src='psc_logo.png' />
                                        </div>
                                        <div className="col d-flex justify-content-start">
                                          <img className="img-responsive" width='100' height='100' src='police_logo.png' />
                                        </div>
                                      </div>
                                    </div>

                                  </div>
                                  <div className='mb-0 pb-0 pt-4'>
                                    <h1 className="text-light font-weight-bolder cbt-title fs-1 p-0"> {admissionInfo || "2022"} Nigeria Police Force Recruitment</h1></div>

                                </div>
                              </td>
                            </tr>

                            <tr>
                              <td colSpan={2}>
                                <div className='mt-0 pt-0'><h5 className="text-dark text-center pt-2 ">{admissionInfo || "2022"} Aptitude/CBT Schedule Slip</h5></div>
                                <div className="d-flex justify-content-center py-4 row">
                                  <div className='col justify-content-end d-flex'>
                                    <img className="img-responsive" width='120' height='120' src={`data:image/png;base64, ${authImg?.photo !== undefined ? authImg?.photo : ''}`} />
                                  </div>
                                  <div className='col align-self-center'>
                                    <div className="text-secondary font-weight-bolder fs-5 text-start text-name">NAME: {aptitudeData?.formData?.surname || ''} {aptitudeData?.formData?.firstname} {aptitudeData?.formData?.middlename}</div>
                                    <div className="text-secondary font-weight-bolder fs-5 text-start">NIN: {aptitudeData?.formData.nin}</div>
                                    <div className="text-secondary fw-bolder"></div>
                                  </div>
                                </div>
                              </td>
                            </tr>
                            <tr className='cand-info-bg'>
                              {
                                !aptitudeData?.workflow?.reasonForDisqualify
                                  ? <td colSpan={2}>{aptitudeData?.workflow?.aptitude?.scheduleSchema
                                    ? <>
                                      <div className='pt-3 '>
                                        <div className="text-center"><b>CBT Date:</b></div>
                                        <div className='text-center info-text'>{aptitudeData?.workflow?.aptitude?.scheduleSchema["cbt-date"] || "Not Avaliable"}</div>
                                      </div>
                                      <div className='pt-3 '>
                                        <div className="text-center"><b>CBT Time:</b></div>
                                        <div className='text-center info-text'>{aptitudeData?.workflow?.aptitude?.scheduleSchema["cbt-time"] || "Not Avaliable"}</div>
                                      </div>
                                      <div className='pt-3 '>
                                        <div className="text-center"><b>CBT VENUE:</b></div>
                                        <div className='text-center info-text'>{aptitudeData?.workflow?.aptitude?.scheduleSchema["cbt-venue"] || "Not Avaliable"}</div>
                                      </div>
                                      <div className='pt-3 '>
                                        <div className="text-center"><b>Username:</b></div>
                                        <div className='text-center info-text'>{aptitudeData?.workflow?.aptitude?.scheduleSchema.username || "Not Avaliable"}</div>
                                      </div>
                                      <div className='pt-3 pb-3 '>
                                        <div className="text-center"><b>Password:</b></div>
                                        <div className='text-center info-text'>{aptitudeData?.workflow?.aptitude?.scheduleSchema?.password || "Not Avaliable"}</div>
                                      </div>
                                    </>
                                    : <>
                                      <h5 className='text-center '>Aptitude Scheduled Credential not Available</h5>
                                    </>
                                  } </td>
                                  : <td colSpan={2}>
                                    <h5 className='text-danger text-center'>Disqualified</h5>
                                    {
                                      aptitudeData?.workflow?.reasonForDisqualify && aptitudeData?.workflow?.reasonForDisqualify.map((reason: any, index: number) => (

                                        <Fragment key={index + 1}>
                                          <h5 className='text-center'>{reason}</h5>
                                        </Fragment>

                                      ))
                                    }

                                  </td>
                              }

                            </tr>
                            <tr>
                              <td>
                                {
                                  aptitudeData?.workflow?.aptitude?.scheduleSchema && <div className='row mt-5'>
                                    <div className="text-center col-5"><img className="img-responsive" src={`data:image/png;base64, ${authImg?.authQR}`} width={200} height={200} alt='qrcode' /></div>
                                    <div className="text-start col mt-4 d-flex align-self-center">Please come along with a copy of this slip during the Aptitude exercise.</div>

                                  </div>
                                }
                              </td>
                            </tr>

                            <tr>
                              <td className="text-center close-col" colSpan={2}>
                                <div className="pcolor1 px-5">
                                  <div className="section-color-tage1"></div>
                                  <div className="section-color-tage2"></div>
                                  <div className="section-color-tage3"></div>
                                  <div className="section-color-tage4"></div>
                                  <div className="section-color-tage5"></div>
                                </div>
                              </td>
                            </tr>

                          </tbody>
                        </Table>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='pt-3 pb-5 justify-content-center d-flex'>
                <button className={hideButton ? "d-none" : "btn w-25 btn--default btn-block"} onClick={() => { setHiddenButton(true) }}>Print</button>
              </div>
            </div>

          </div>
        </div>
      }
      <Footer />
      <Toaster></Toaster>
    </React.Fragment>
  )
}

export default PrintAptitudeSlip
