import axios from 'axios';
import { BASE_URL_ADDR } from '../Constants/BaseURL';

axios.defaults.baseURL = BASE_URL_ADDR;
// axios.defaults.baseURL = 'http://localhost:8000';

axios.interceptors.request.use(function (config:any) {
  // Do something before request is sent
  config.headers.post['Content-Type'] = 'application/json';
  config.headers.common.Accept = 'application/json';
  config.headers.post['Access-Control-Allow-Origin'] = "*";
  config.baseURL = BASE_URL_ADDR;
  // console.log("heeeeehehehehhehheader", config)
  return config;
}, function (error) {
  // Do something with request error
  return Promise.reject(error);
});

// section superadmin
const applicantsService = {
  index: async (data:{nin: number; phone:string; email:string; candidateRole: string}) => {
    return await axios.post(`/recruitment/nin-enquiry?module=portal&admissionId=3`, data);
  },

  resume: async (data: {nin: string, rrr: string}) => {
    return await axios.get(`/recruitment/resume?nin=${data.nin}&rrr=${data.rrr}&module=portal&admissionId=3`,);
  },

  search: async (nin: number) => {
    return await axios.get(`/recruitment/nimc/extract-nin?nin=${nin}&module=portal&admissionId=3`);
  },

  aptitudeQuery: async (nin: number) => {
    return await axios.get(`/query-aptitude-status?nin=${nin}`);
  },

  institutionQuery: async (nin: number) => {
    return await axios.get(`/institution/query-recruit-status?nin=${nin}`)
  },
  downloadShortlisted: async () => {
    return await axios.get('institution/download-list')
  },
  specialistQuery: async (nin: number) => {
    return await axios.get(`/query-interview-status?nin=${nin}`);
  },

  uploadFile: async (data:{document:any, nin:number, documentType:string}) => {
    return await axios.post(`/recruitment/upload-credentials?module=portal&admissionId=3`, data);
  },
  getRrr: async (data:{nin:number}) => {
    return await axios.get(`/recruitment/request-tranx-info?nin=${data.nin}&module=portal&admissionId=3`);
  },
  pscreeningSlip: async (data:{nin:number}, admissionId:number) => {
    return await axios.get(`/query-pscreen-status?nin=${data.nin}&admissionId=${admissionId}`)
  },

}

export default applicantsService
