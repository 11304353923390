import React, { useEffect, useState } from 'react'
import { Toast } from 'react-bootstrap'
import { useNavigate, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { RegisterFooter, RegisterHeader, RegistrationStatusFail, RegistrationStatusPass } from '../../Components/Index'

import './RegistrationStatus.scss'

const RegistrationStatus = () => {
  const { refid } = useParams<any>()
  const history = useNavigate()

  // eslint-disable-next-line no-unused-vars
  const [disableStatus, setDisableStatus] = useState(false)
  const values = useSelector((state: { user:any }) => state.user.success)
  // console.log("ffffffggggg", values)

  useEffect(() => {
    if (!values) {
      return history(-1)
    } else {
      setDisableStatus(true)
    }
  }, [refid, values])

  return (
    <div className="confirmation">
      <div className="register-main">
        <RegisterHeader></RegisterHeader>
          <div className="">
              <div className="register-inner">
                  <div className="spacer"></div>

                  {Object.keys(values).length !== 0 && values !== undefined && (<>
                  {values.workflow.aptitude.qualified === 1
                    ? (<RegistrationStatusPass value={values} disable={disableStatus}/>)
                    : (<RegistrationStatusFail value={values} disable={disableStatus}/>)}
                  </>)}

              </div>
        <Toast/>
      </div>
            <RegisterFooter></RegisterFooter>
    </div>
  </div>
  )
}

export default RegistrationStatus
