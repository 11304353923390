import React from 'react'
import { Toast } from 'react-bootstrap'
import { MedicalStatusFail, RegisterFooter, RegisterHeader } from '../../../Components/Index'

import './MedicalStatus.scss'

const MedicalStatusFailure = () => {
  return (
    <div className="confirmation">
      <div className="register-main">
        <RegisterHeader></RegisterHeader>
          <div className="">
              <div className="register-inner">
                  <div className="spacer"></div>
                  <MedicalStatusFail />

              </div>
        <Toast/>
      </div>
            <RegisterFooter></RegisterFooter>
    </div>
  </div>
  )
}

export default MedicalStatusFailure
