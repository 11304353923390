
import React, { useEffect, useState } from 'react'
import RegistrationFail from '../../Components/ConfirmationSlip/RegistrationFail/RegistrationFail';

const VerifyJamb = ({ formData }:any) => {
  const [boxErrorMessage, setBoxErrorMessage] = useState<string>("");
  // eslint-disable-next-line no-unused-vars
  const [jambValidated, setJambValidated] = useState(false);

  const [jambResultData, setJambResultData] = useState<any>(null);

  useEffect(() => {
    setJambResultData(formData.dataset.jamb.dataset)
  }, [formData])

  return (
          <div className="container">

              {
                  boxErrorMessage && <RegistrationFail message={boxErrorMessage} clearErrorBox={() => setBoxErrorMessage('')} />
              }

        {!boxErrorMessage && jambResultData && <>
       <div className="section-container mt-2 rounded">
            <div className="section-item-container">
            </div>
            <div className="col-md-12 text-white rounded text-center card-header section-header-bg">
                {/* <h1>Your National Identification Number (NIN) </h1> */}
                <h1>Application (step 1 of 2)</h1>
            </div>
            <div className="section-item-container">
            </div>
       </div>

      <section>
        <div className="accordion" id="accordionExample">
            <div className="card">
                <div className="card-header section-header-bg" id="headingOne">
                <h2 className="mb-0">
                    <button className="btn text-white font-weight-bolder btn-block text-center" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                    Your Jamb result
                    </button>
                </h2>
                </div>

                <div id="collapseOne" className="collapse" aria-labelledby="headingOne" data-parent="#accordionExample">
                    <div className="card">
                        <div className="card-body">
                        <div className="table-responsive">
                                <table className="table">
                                    <tbody>
                                            <tr>
                                                <td>Exam year</td>
                                                <td>2022</td>
                                            </tr>
                                    </tbody>
                                </table>

                                <table className="table">
                                <thead className='font-weight-bold'>
                                    <tr>
                                        <td>
                                        Subject
                                        </td>
                                        <td>
                                        Score
                                        </td>
                                    </tr>
                                </thead>
                                <tbody>
                                   <tr>
                                        <td>
                                            {jambResultData['SUBJECT-1']}
                                        </td>
                                        <td>
                                            {jambResultData['SUBJECT-1-SCORE']}
                                        </td>
                                    </tr>
                                   <tr>
                                        <td>
                                            {jambResultData['SUBJECT-2']}
                                        </td>
                                        <td>
                                            {jambResultData['SUBJECT-2-SCORE']}
                                        </td>
                                    </tr>
                                   <tr>
                                        <td>
                                            {jambResultData['SUBJECT-3']}
                                        </td>
                                        <td>
                                            {jambResultData['SUBJECT-3-SCORE']}
                                        </td>
                                    </tr>
                                   <tr>
                                        <td>
                                            {jambResultData['SUBJECT-4']}
                                        </td>
                                        <td>
                                            {jambResultData['SUBJECT-4-SCORE']}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Total Score</td>
                                        <td>{jambResultData['OVERALL-SCORE']}</td>
                                    </tr>
                                    <tr>
                                        <td>Course</td>
                                        <td>{jambResultData['COURSE-OF-STUDY']}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>

        </section></>}
        </div>
  )
}

export default VerifyJamb
