import { message } from 'antd'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Loader } from '../../../../Components'
import Footer from '../../../../Layout/Footer'
import RegisterHeader from '../../Components/Register/RegisterHeader/RegisterHeader'
import Application from '../Application/Application'
import './VerifyRrrPayment.scss'
import ConfirmationAlert from '../../../../Components/ConfirmationAlert/ConfirmationAlert'
import applicantsService from '../../../../Services/applicantsService'
import VerifyJamb from '../Application/VerifyJamb'
import RegistrationFail from '../../Components/ConfirmationSlip/RegistrationFail/RegistrationFail'

interface FieldValues{
  nin: string;
  rrr:string;
}
const VerifyRrrPayment = () => {
  // eslint-disable-next-line no-unused-vars
  const [setLoadingReq, setLoadingRequest] = useState<boolean>(false)
  // eslint-disable-next-line no-unused-vars
  const [jambVerificationStatus, setJambVerificationStatus] = useState<boolean>(false)

  const [boxErrorMessage, setBoxErrorMessage] = useState<string>('');
  const [formData, setFormData] = useState('')
  const { register, handleSubmit, formState: { errors } } = useForm<FieldValues>()
  // eslint-disable-next-line no-unused-vars
  const [loader, setLoader] = useState(false)
  const [paymentStatus, setPaymentStatus] = useState(false)

  // eslint-disable-next-line no-unused-vars
  const [rrrData, setRrrData] = useState(null)

  const throwError = (mess: string = 'There was an error submitting application!') => {
    message.error({
      content: mess,
      style: {
        zIndex: '200000'
      }
    })
  }

  // verify transaction using RRR
  const onSubmit = handleSubmit(async (data:any) => {
    const rrr = data.rrr
    setRrrData(rrr)

    setLoadingRequest(true)
    setLoader(true)

    console.log('data.nin', data.nin)

    await applicantsService.resume(data)
      .then((response:any) => {
        setLoader(false)
        console.log('response ss', response.data)
        if (response.data.metadata.hasCommittedPayment === 0) {
          throwError(response.data.message)
          setPaymentStatus(false)
          setLoadingRequest(false)
          return ConfirmationAlert(
            () => {
              window.location.href = '/'
            },
            'Payment is pending!',
            'No payment yet. Kindly pay to continue your application. Click close to exit',
            'Close'
          )
        } else {
          setLoadingRequest(false)
          setPaymentStatus(true)

          setFormData(response.data)

          return ConfirmationAlert(
            () => {
            },
            'Payment verified!',
            'Your payment verification is succesful. Click close to continue',
            'Close'
          )
        }
      })
      .catch((error: any) => {
        console.log(error)
        setLoader(false)
        setLoadingRequest(false)

        if (error?.response?.data?.display === 'html') {
          setBoxErrorMessage(error?.response?.data?.message);
        } else {
          throwError(error.response.data.message)
          throwError(error?.response?.data?.message || 'NIN validation failed!')
        }
      })
  }
  )

  return (
    <React.Fragment>

       <div className="slip-confirmation h-100">
          <RegisterHeader></RegisterHeader>
          {
            boxErrorMessage && <RegistrationFail message={boxErrorMessage} clearErrorBox={() => setBoxErrorMessage('')} />
        }
            {!boxErrorMessage && <div className="register-inner bg-light">
               {paymentStatus === false && <div className="row h-100">
                   <div className="col-md-4 offset-md-4 my-auto">
                   <div className="card mt-5">
                    <div className="card-header text-center bg-white">
                      <div className="c-header-crib-2">
                        <span></span>
                        <span></span>
                        <span></span>
                      </div>
                      <h5>Resume Recruitment Application </h5>
                      <div className="c-header-crib">
                        <span></span>
                        <span></span>
                        <span></span>
                      </div>
                    </div>
                    <div className="card-body">
                      <form onSubmit={onSubmit}>
                        <div className="form-group">
                            <label> Your Payment RRR</label>
                          <input className="form-control" {...register('rrr', { required: 'RRR field cannot be empty' })}></input>
                          <div className="register--error text-danger">
                              {errors.rrr && errors.rrr.message}
                          </div>
                              <small>RRR generated from this portal & used for payment.</small>
                        </div>

                        <div className="form-group">
                            <label> Your NIN number</label>
                          <input className="form-control" {...register('nin', { required: 'NIN field cannot be empty' })}></input>
                          <div className="register--error text-danger">
                              {errors.nin && errors.nin.message}
                          </div>
                          <small> NIN used for registration.</small>
                        </div>

                        <div className="form-group text-center">
                          <button className="btn btn-warning px-5 font-weight-bold text-dark">Verify Now</button>
                        </div>
                      </form>
                    </div>
                </div>
              </div>
            </div>}

           {paymentStatus === true && <div className=''>
             <VerifyJamb formData={formData}></VerifyJamb>
             <Application data={formData}></Application>
            </div>}
          </div>}

      <Footer/>
    </div>

      <Loader show={loader}/>
      {/* <ToastContainer /> */}
    </React.Fragment>
  )
}

export default VerifyRrrPayment
