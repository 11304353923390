import React from 'react'
import { RegisterHeader } from '../../../Components/Index'

import igPresident from "../../../../../Assets/images/p1.jpeg"
import olIgPresident from "../../../../../Assets/images/p2.jpeg"
import "./News.scss"
import { Link } from 'react-router-dom'
import Footer from '../../../../../Layout/Footer'
import { LazyLoadImage } from 'react-lazy-load-image-component';
const News = () => {
  return (
    <React.Fragment>
    <RegisterHeader/>
        <div className='container pt-4'>
            <h1>News</h1>
        </div>
        <div className="news py-5 px-2  d-flex align-items-center">
            <div className="container">
                <div className="row">
                    <div className="col-md-6">
                        <div className="card">
                            <div className='news-image-cont'>
                                {/* <img src={igPresident} alt="" className='w-100'></img> */}
                                <LazyLoadImage
                                    src={igPresident} // use normal <img> attributes as props
                                    width={'100%'} />
                            </div>
                            <div className="card-body">
                                <Link to="/news-detail">
                                    <h3 className='font-weight-bolder'> 2022 NPF Recruitment Exercice begins  </h3>
                                </Link>
                                <div className='card-news-date pt-0 mb-3'>July 17, 2022</div>
                                <div className='card-new-content'>
                                All empower batch c applicants are expected to update their profile and take test for possible shortlisting applicants are expected to update their profile and take test for possible shortlisting.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="card">
                            <div className='news-image-cont'>
                                {/* <img src={olIgPresident} alt="" className='w-100'></img> */}
                                <LazyLoadImage
                                    alt=""
                                    src={olIgPresident} // use normal <img> attributes as props
                                    width={'100%'} />
                            </div>
                            <div className="card-body">
                                <Link to="/news-detail">
                                <h3 className='font-weight-bolder'> How to apply for the 2022 NPF Recruitment Exercice </h3>
                                </Link>
                                <div className='card-news-date pt-0 mb-3'>June 25, 2020</div>
                                <div className='card-new-content'>
                                All empower batch c applicants are expected to update their profile and take test for possible shortlisting applicants are expected to update their profile and take test for possible shortlisting.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    <Footer/>
</React.Fragment>
  )
}
export default News
