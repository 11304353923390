import React, { FC } from 'react'
interface propsType {
    value: any;
    disable: boolean;
}
const Ssce:FC<propsType> = ({ value, disable }) => {
  // eslint-disable-next-line no-unused-vars
  const { ssce } = value
  return (

    <div className="card mt-5">
    <div className="card-header cbt-heading text-light text-center font-weight-boder">
        <div className="c-header-crib-2 ">
            <span></span>
            <span></span>
            <span></span>
        </div>
       APTITUDE TEST SCHEDULE INFORMATION
        <div className="c-header-crib">
            <span></span>
            <span></span>
            <span></span>
        </div>
    </div>
    {disable === true && (
    <div className="card-body">
        <div className="container-fluid">
            <table className="table table-stripe">
                <tr>
                    <td>Venue</td>
                    <td></td>
                </tr>
                 <tr>
                    <td>Time</td>
                    <td></td>
                </tr>
                 <tr>
                    <td>Seat Number</td>
                    <td></td>
                </tr>
            </table>
        </div>
    </div>
    )}
</div>
  )
}

export default Ssce
