import React, { FC } from 'react'
import Table from 'react-bootstrap/esm/Table'
import './PersonalInformationSlip.scss'
import emptyAvarter from "./../../../../../Assets/images/user-empty-avatar.png";
import { capitalize } from '../../../../../Hooks';

interface propsType {
    value: any;
    disable: boolean;
}

const PersonalInformationSlip:FC<propsType> = ({ value, disable }) => {
  const {
    nin,
    positionApplied,
    gender,
    title,
    lgaOfOrigin,
    lastname,
    firstname,
    lgaOfBirth,
    stateOfBirth,
    countryOfBirth,
    stateOfOrigin,
    townOfOrigin,
    lgaOfResidence,
    townOfResidence,
    middlename,
    stateOfResidence,
    streetAddressOfOrigin,
    streetAddressOfPermanent,
    streetAddressOfResidence,
    email,
    state,
    dob,
    mobile,
    birthdate,
    profession,
    maritalstatus,
    emplymentstatus,
    educationalLevel,
    document,
    reference_id,
    lga,
    maritalStatus,
    nationality,
    lgaOfPermanent
  } = value

  // const { title } = JSON.parse(value.extras).ninc

  // console.log("value value value value value", value)
  const stateOfResidenceName:any = state[2].data?.filter((obj:any) => {
    return obj.id === stateOfResidence?.toString()
  })[0]?.name

  const stateOfOriginName:any = state[2].data?.filter((obj:any) => {
    return obj.id === stateOfOrigin?.toString()
  })[0]?.name

  const lgaOfResidenceName:any = lga[2].data?.filter((obj:any) => {
    return obj.id === lgaOfResidence?.toString()
  })[0]?.name

  const myPhoto = document?.find((e: { fileType: string; }) => e.fileType === "profilePhoto");

  return (
        <div className="card">
        <div className="card-header cbt-heading text-white font-weight-bold text-center">
            <div className="c-header-crib-2">
                <span></span>
                <span></span>
                <span></span>
            </div>
            PERSONAL INFORMATION
            <div className="c-header-crib">
                <span></span>
                <span></span>
                <span></span>
            </div>
        </div>
       {disable === true && (
         <div className="card-body">
            <div className="container">
              <div className="row">
                <div className="col-md-6">
                    <div className="form-group">
                      <label> First Name </label>
                      <input className="form-control rounded-0" value={capitalize(firstname)} readOnly></input>
                    </div>

                    <div className="form-group">
                      <label> Middle Name </label>
                      <input className="form-control rounded-0" value={capitalize(middlename)} readOnly></input>
                    </div>

                  <div className="form-group">
                    <label> Lastname </label>
                    <input className="form-control rounded-0" value={capitalize(lastname)} readOnly></input>
                  </div>
                  <div className="form-group">
                    <label> Phone Number </label>
                    <input className="form-control rounded-0" value={capitalize(mobile)} readOnly></input>
                  </div>
                  <div className="form-group">
                    <label> Email Address  </label>
                    <input className="form-control rounded-0" value={capitalize(email)} readOnly></input>
                  </div>
                  <div className="form-group">
                    <label> State of Residence</label>
                    <input className="form-control rounded-0" value={capitalize(stateOfResidence)} readOnly></input>
                  </div>
                  <div className="form-group">
                    <label> Local Government of Origin</label>
                    <input className="form-control rounded-0" value={capitalize(lgaOfPermanent)} readOnly></input>
                  </div>
                  <div className="form-group">
                    <label> Town of Residence</label>
                    <input className="form-control rounded-0" value={streetAddressOfResidence} readOnly></input>
                  </div>
                  <div className="form-group">
                    <label>Local Government of Residence </label>
                    <input className="form-control rounded-0" value={lgaOfResidence} readOnly></input>
                  </div>
                  <div className="form-group">
                    <label>Address of Residence </label>
                    <input className="form-control rounded-0" value={streetAddressOfResidence} readOnly></input>
                  </div>

                  <div className="form-group">
                    <label> Marital Status</label>
                    <input className="form-control rounded-0" value={capitalize(maritalStatus)} readOnly></input>
                  </div>

                  <div className="form-group">
                    <label> Profession</label>
                    <input className="form-control rounded-0" value={profession} readOnly></input>
                  </div>

                </div>

                <div className="col-md-6">

                <div className="form-group">
                    <label> NIN </label>
                    <input className="form-control rounded-0" value={nin} readOnly></input>
                  </div>
                  <div className="form-group">
                    <label> Reference Number </label>
                    <input className="form-control rounded-0" value={reference_id} readOnly></input>
                  </div>
                  <div className="form-group">
                    <label> Sex</label>
                    <input className="form-control rounded-0" value={gender} readOnly></input>
                  </div>
                  <div className="form-group">
                    <label> Educational Level</label>
                    <input className="form-control rounded-0" value={educationalLevel} readOnly></input>
                  </div>
                  <div className="form-group">
                    <label> State of Origin</label>
                    <input className="form-control rounded-0" value={state} readOnly></input>
                  </div>
                  <div className="form-group">
                    <label> Town of Origin</label>
                    <input className="form-control rounded-0" value={townOfOrigin} readOnly></input>
                  </div>
                  <div className="form-group">
                    <label> Address of Origin</label>
                    <input className="form-control rounded-0" value={streetAddressOfPermanent} readOnly></input>
                  </div>
                  <div className="form-group">
                    <label> Date of Birth</label>
                    <input className="form-control rounded-0" value={dob} readOnly></input>
                  </div>
                  <div className="form-group">
                    <label> Employment Status</label>
                    <input className="form-control rounded-0" value={emplymentstatus} readOnly></input>
                  </div>
                  <div className="form-group">
                    <label>Local Government of Birth</label>
                    <input className="form-control rounded-0" value={lgaOfBirth} readOnly></input>
                  </div>
                  <div className="form-group">
                    <label> State of Birth</label>
                    <input className="form-control rounded-0" value={stateOfBirth} readOnly></input>
                  </div>

                  <div className="form-group">
                    <label> Country of Birth</label>
                    <input className="form-control rounded-0" value={nationality} readOnly></input>
                  </div>

                </div>
                <div className="col-md-12 form-group d-none">
                <Table bordered hover>
                      <tr className="d-none">
                        <td className="text-center" colSpan={2}>
                          <div>
                              <img src={myPhoto?.remoteURL || emptyAvarter} alt="" className="w-25"/>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className="text-right">Title :</td>
                        <td>{title}</td>
                      </tr>
                      <tr>
                        <td className="text-right">Last name :</td>
                        <td>{lastname}</td>
                      </tr>
                      <tr>
                        <td className="text-right">Firstname :</td>
                        <td>{firstname}</td>
                      </tr>
                      <tr>
                        <td className="text-right">NIN :</td>
                        <td>{nin}</td>
                      </tr>
                      <tr>
                        <td className="text-right">Position Applied :</td>
                        <td>{positionApplied}</td>
                      </tr>
                      <tr>
                        <td className="text-right">Phone Number 1 :</td>
                        <td>{mobile[0]}</td>
                      </tr>
                      <tr>
                        <td className="text-right">Phone Number 2 :</td>
                        <td>{mobile[1]}</td>
                      </tr>
                      <tr>
                        <td className="text-right">Email Address 1 :</td>
                        <td>{email[0]}</td>
                      </tr>
                      <tr>
                        <td className="text-right">Email Address 2 :</td>
                        <td>{email[1]}</td>
                      </tr>
                      <tr>
                        <td className="text-right">Sex :</td>
                        <td>{gender}</td>
                      </tr>
                      <tr>
                        <td className="text-right">Educational Level :</td>
                        <td>{educationalLevel}</td>
                      </tr>
                      <tr>
                        <td className="text-right">State of Origin :</td>
                        <td>{stateOfOriginName}</td>
                      </tr>
                      <tr>
                        <td className="text-right">Town of Origin :</td>
                        <td>{townOfOrigin}</td>
                      </tr>
                       <tr>
                        <td className="text-right">Address of Origin :</td>
                        <td>{streetAddressOfOrigin}</td>
                      </tr>
                      <tr>
                        <td className="text-right">Date of Birth :</td>
                        <td>{birthdate}</td>
                      </tr>
                      <tr>
                        <td className="text-right">State of Residence :</td>
                        <td>{stateOfResidenceName}</td>
                      </tr>

                      <tr>
                        <td className="text-right">Local Government of Origin :</td>
                        <td>{lgaOfOrigin}</td>
                      </tr>

                      <tr>
                        <td className="text-right">Town of Residence :</td>
                        <td>{townOfResidence}</td>
                      </tr>
                      <tr>
                        <td className="text-right">Local Government of Residence :</td>
                        <td>{lgaOfResidenceName}</td>
                      </tr>
                      <tr>
                        <td className="text-right">Address of Residence :</td>
                        <td>{streetAddressOfResidence}</td>
                      </tr>

                      <tr>
                        <td className="text-right">Employment Status :</td>
                        <td>{emplymentstatus}</td>
                      </tr>

                      <tr>
                        <td className="text-right">Local Government of Birth :</td>
                        <td>{lgaOfBirth}</td>
                      </tr>
                      <tr>
                        <td className="text-right">State of Birth :</td>
                        <td>{stateOfBirth}</td>
                      </tr>
                      <tr>
                        <td className="text-right"> Marital Status :</td>
                        <td>{maritalstatus}</td>
                      </tr>
                      <tr>
                        <td className="text-right">Country of Birth :</td>
                        <td>{countryOfBirth}</td>
                      </tr>
                      <tr>
                        <td className="text-right">Profession :</td>
                        <td>{profession}</td>
                      </tr>
                    </Table>
                </div>

              </div>
            </div>
        </div>)}
    </div>
  )
}

export default PersonalInformationSlip
