import React, { useEffect, useState } from 'react'
import { Toast } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
// eslint-disable-next-line no-unused-vars
import { MedicalDetails, MedicalStatusFail, MedicalStatusPass, RegisterFooter, RegisterHeader } from '../../../Components/Index'

import './MedicalStatus.scss'
import { USER_FAILURE, USER_SUCCESS } from '../../../../../Constants/UserConstants'
import userService from '../../../../../Services/userService'
import { Loader } from '../../../../../Components'
import Footer from '../../../../../Layout/Footer'

const MedicalStatus = () => {
  const { refid } = useParams<any>()

  const dispatcher = useDispatch();
  // eslint-disable-next-line no-unused-vars
  const [disableStatus, setDisableStatus] = useState(false)
  const [values, setValue] = useState<any>()
  const [loader, setLoader] = useState(false)

  const { success, loading } = useSelector((state: { user:any }) => state.user)
  console.log("SUCC", success)
  useEffect(() => {
    if (typeof success === "undefined" && typeof success !== "object") {
      setLoader(true)
      userService.getDataMedical(refid).then((res:any) => {
        console.log(refid)
        setLoader(false)
        dispatcher({ type: USER_SUCCESS, data: res.data });
        console.log("MELON", res)
        setValue(res.data)
      }, (error: {messgae: string}) => {
        dispatcher({ type: USER_FAILURE, data: error.messgae });
        window.location.href = "/"
      })
    }
  }, [])

  if (loading && loading === true) {
    setLoader(true)
  }

  useEffect(() => {
    if (typeof success !== "undefined") {
      setValue(success)
    }
  }, [success])
  console.log("HEY", values)
  return (
    <div className="confirmation">
      <div className="register-main">
        <RegisterHeader></RegisterHeader>
          <div className="">
              <div className="register-inner">
                  <div className="spacer"></div>
                  { values && Object.keys(values).length !== 0 && values !== undefined && (<>
                  {values?.dataset?.workflow?.medical?.metadata.initialized === 1
                    ? (<MedicalDetails value={values} disable={disableStatus}/>)
                    : (<MedicalStatusFail />)}
                  </>)}

              </div>
        <Toast/>
      </div>
        <Footer/>
    </div>
    <Loader show={loader}/>
  </div>
  )
}

export default MedicalStatus
