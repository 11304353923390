import React from 'react';
import { Link } from 'react-router-dom';

const NotFound = () => (
  <div style={{ height: "100vh" }}>
    <div className="row h-100">
        <div className="col-md-6 offset-md-3 my-auto text-center">
            <h1>404 - Not Found!</h1>
            <Link to="/">
            Go Home
            </Link>
        </div>
    </div>
  </div>
);

export default NotFound;
