/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import MentainanceMode from '../../Pages/Frontend/Interfaces/MentainanceMode/MentainanceMode';
import userService from '../../Services/userService';

function ManagedRoutes ({ children }: { children: React.ReactNode }) {
  const [routeState, setRouteState] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const navigate = useNavigate();
  const currentRoute = location.pathname;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const commands = localStorage.getItem('MENU_COMMAND');
        const { data } = commands !== null ? JSON.parse(commands) : null;
        setRouteState(data);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    // Loading indicator while fetching data
    return <div>Loading...</div>;
  }

  if (routeState?.maintenance.switch === 1) {
    return <MentainanceMode />;
  } else {
    if ((routeState?.aptitude.switch === 1) && (currentRoute === "/print-aptitude-slip" || currentRoute === "/report-wrong-schedule" || currentRoute === '/print-speciality-slip')) {
      return <>{children}</>;
    } else if ((routeState?.registrationForm.switch === 1) && (currentRoute === "/guideline")) {
      return <>{children}</>
    } else if ((routeState?.pscreen.switch === 1) && (currentRoute === "/print-screen-status" || currentRoute === "/physical-screening-acknowlegement" || currentRoute === "/status-upload-request")) {
      return <>{children}</>
    } else if ((routeState?.medical.switch === 1) && (currentRoute === "/medical-print-slip" || currentRoute === "/status-nin-medical" || currentRoute === "/report-wrong-schedule-medical" || currentRoute === "/medical-status-failure" || currentRoute.match("medical-slip/*") || currentRoute.match("/medical-status/*"))) {
      return <>{children}</>
    } else if ((routeState?.institution.switch === 1) && (currentRoute === "/institution-print-slip" || currentRoute === "/report-rejection" || currentRoute === "/report-wrong-schedule-admission" || currentRoute === "/download-shortlisted")) {
      return <>{children}</>
    } else if (currentRoute === '/' || currentRoute === '/apply') {
      return <React.Fragment>{children}</React.Fragment>;
    } else {
      return <Navigate to="/" replace />;
    }
  }
}

export default ManagedRoutes;
