import React, { FC } from 'react'
import './CollegeDetails.scss'
import emptyAvarter from './../../../../../Assets/images/user-empty-avatar.png'
import Table from 'react-bootstrap/esm/Table'
// import { useNavigate } from 'react-router';
// import { useParams } from 'react-router-dom';

interface propsType {
    value: any;
    disable: boolean;
}

const CollegeDetails:FC<propsType> = ({ value, disable }) => {
  const {
    nin,
    Applicant_Name,
    // lga,
    date = '10th - 16th January, 2022',
    Institution,
    photo
  } = value

  // const history = useNavigate()
  // const { refid } = useParams<any>()

  const uploadPic = (e:any) => {
    console.log(e)
  }

  // console.log("VAL VAL", value)
  return (
    <div className="cbt-details my-5">
        {disable &&
          <div className="container-fluid">
            <div className="row d-flex justify-content-center">
              <div className="col-md-9 offset-md">
                <div className="card bg-white p-2">
                  <div className="table-responsive">
                  <Table bordered>
                    <tbody>
                      <tr>
                      <td colSpan={2} className="p-0">
                        <div className="card-header text-center text-dark bg-white p-1">
                          <div className="row">
                            <div className="col-2">
                               <img src={'logo.png'} alt="police" className="cbp-logo"/>
                            </div>
                            <div className="col-10">
                              <h2 className="text-dark font-weight-bolder cbt-title">Nigeria Police Force</h2>
                              <h6 className="text-dark">Department of Training and Development</h6>
                            </div>
                          </div>
                        </div>
                      </td>
                      </tr>
                      <tr>
                        <td colSpan={2}>
                          <div className="d-flex justify-content-center align-item-center">
                            <div className="cbt-profile-photo-content">
                            {photo
                              ? (<img src={`data:image/png;base64, ${photo}`} alt="" className="w-100 cbt-profile-photo"/>)
                              : (<img src={emptyAvarter} alt="" className="w-100 cbt-profile-photo" onClick={(e) => uploadPic(e)}/>)
                            }
                            </div>
                          </div>
                        </td>
                      </tr>
                      {/* <tr>
                          <td className="text-right">Status:</td>
                          <td className="text-success font-bold font-weight-bold">{workflow.aptitude.qualified === 1 ? "Qualified" : "Not qualified"}</td>
                        </tr>  */}
                          <tr>
                            <td className="text-right w-50">Full Name:</td>
                            <td className="font-weight-bold  w-50">{Applicant_Name} </td>
                          </tr>
                          <tr>
                          <td className="text-right  w-50">NIN :</td>
                          <td className="font-weight-bold  w-50">{nin} </td>
                        </tr>
                        {/* <tr>
                          <td className="text-right  w-50">Password (Ref No):</td>
                          <td className="font-weight-bold  w-50">{reference_id} </td>
                        </tr> */}
                        <tr>
                          <td className="text-right  w-50">Date:</td>
                          <td className="font-weight-bold  w-50">{date} </td>
                        </tr>

                        {/* <tr>
                          <td className="text-right  w-50">Time:</td>
                          <td className="font-weight-bold w-50"></td>
                        </tr> */}
                          <tr>
                          <td className="text-right w-50">Institution :</td>
                          <td className="font-weight-bold w-50">{Institution}</td>
                        </tr>
                    </tbody>
                  </Table>
                  </div>
                </div>
              </div>
            </div>
        </div>}
        <div className="text-center text-black cpb-note">
            <div>NOTE: This slip should be presented at the College.</div>
             <div> No admission will be allowed without it.</div>
        </div>
    </div>
  )
}

export default CollegeDetails
