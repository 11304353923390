import React from 'react'
import { RegisterHeader, Slider } from '../../Components/Index'

import igPresident from '../../../../Assets/images/p1.jpeg'
import olIgPresident from '../../../../Assets/images/std1139317.jpg'
import olIgPresident97 from '../../../../Assets/images/admission.jpeg'
import olIgboy from '../../../../Assets/images/p2.jpeg'
import p3 from '../../../../Assets/images/p3.jpeg'
import pgirl from '../../../../Assets/images/pgirl.jpeg'
import pAc from '../../../../Assets/images/pAc.jpeg'

import { Link } from 'react-router-dom'
import './Home.scss'
import Footer from '../../../../Layout/Footer'

const Home = () => {
  return (
    <React.Fragment>
      <RegisterHeader/>
      <Slider/>

         <div className="home overflow-hidden">
          <div className="container h-100">
            <div className="row h-100 my-5 overflow-hidden">
              <div className="col-md-6 my-auto" data-aos='fade-up' data-aos-delay='0'>
              <Link to={'/news-detail'}>{/** rgba(52, 52, 100, 0.95) */}
                <div className="section-content shadow-lg" style={{ backgroundImage: `linear-gradient( to bottom, rgba(152, 152, 100, 0.677), rgba(52, 52, 100, 0.962) ), url(${olIgPresident97})` }}>
                  <div className="section-content-inner align-self-center vertical-center text-center w-100">
                    <h3>2022 Recruitment Form</h3>
                  </div>
                </div>
                </Link>
              </div>

              <div className="col-md-6 my-5" data-aos='fade-up' data-aos-delay='50'>
                <Link to={'/news-detail'}>
                  <div className="section-content shadow-lg" style={{ backgroundImage: `linear-gradient( to bottom, rgba(152, 152, 100, 0.677), rgba(52, 52, 100, 0.962) ), url(${olIgPresident})` }}>
                    <div className="section-content-inner align-self-center vertical-center text-center w-100">
                    <h3>Check Recruitment Status</h3>
                    </div>
                  </div>
                </Link>
              </div>

              <div className="col-md-6 my-0" data-aos='fade-up' data-aos-delay='0'>
                <Link to={'/news-detail'}>
                  <div className="section-content shadow-lg" style={{ backgroundImage: `linear-gradient( to bottom, rgba(152, 152, 100, 0.677), rgba(52, 52, 100, 0.962) ), url(${pAc})` }}>
                    <div className="section-content-inner align-self-center vertical-center text-center w-100">
                    <h3>Our Academic Programs</h3>
                    </div>
                  </div>
                </Link>
              </div>

              <div className="col-md-6 my-5 my-md-0" data-aos='fade-up' data-aos-delay='50'>
              <Link to={'/news-detail'}>{/** rgba(52, 52, 100, 0.95) */}
                <div className="section-content shadow-lg" style={{ backgroundImage: `linear-gradient( to bottom, rgba(152, 152, 100, 0.677), rgba(52, 52, 100, 0.962) ), url(${pgirl})` }}>
                  <div className="section-content-inner align-self-center vertical-center text-center w-100">
                    <h3>Recruitment Guideline</h3>
                  </div>
                </div>
                </Link>
              </div>
            </div>
          </div>
        </div>

        <section className='bg-secondary-0 py-5 border border-light news-home'>
          <div className='container'>
              <div className="row pb-4">
                <div className="col-md-6 offset-md-3 text-center">
                  <h3>Latest News</h3>
                  <div>Keep up with the latest updates about programmes and events</div>
                </div>
              </div>
              <div className="row overflow-hidden">
                <div className="col-md-4">
                  <div className="card rounded" data-aos='fade-right'>
                    <img src={igPresident} className="home-news-image rounded"></img>
                    <div className="card-body">
                      <Link to={'/news-detail'}>
                         <h4 className='mb-1'>2021 CBT results released</h4>
                      </Link>
                      <div className='card-news-date pt-0 mb-3'>June 25, 2020</div>
                      <div className='card-new-content'>
                       All empower batch c applicants are expected to update their profile and take test for possible shortlisting...
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="card rounded" data-aos='fade-up'>
                    <img src={olIgboy} className="home-news-image rounded"></img>
                    <div className="card-body">
                      <Link to={'/news-detail'}>
                         <h4 className='mb-1'>How to register for 2022 session</h4>
                      </Link>
                      <div className='card-news-date pt-0 mb-3'>June 25, 2020</div>
                      <div className='card-new-content'>
                       All empower batch c applicants are expected to update their profile and take test for possible shortlisting...
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="card rounded" data-aos='fade-left'>
                      <img src={p3} className="home-news-image rounded"></img>
                    <div className="card-body">
                      <Link to={'/news-detail'}>
                         <h4 className='mb-1'>Instructions for 2022 Screening</h4>
                      </Link>
                      <div className='card-news-date pt-0 mb-3'>June 25, 2020</div>
                      <div className='card-new-content'>
                       All empower batch c applicants are expected to update their profile and take test for possible shortlisting...
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className='text-center my-4'>
                  <Link to={'/news'}>
                    <button className="btn btn-warning font-weight-bolder rounded-0 text-dark">News Page</button>
                  </Link>
              </div>

          </div>
        </section>
        <section className='container'>
           <div className="text-center my-5 contact-section">
              <div>
                 <h4>Got Questions?</h4>
              </div>
              <div className="mt-3">
              For enquiries, complaints or requests, feel free to reach out to us.
              </div>
              <div className="mt-3">
                <Link to={'/contact'}>
                   <button className="btn btn-warning text-dark">Contact Us</button>
                </Link>
              </div>
           </div>
        </section>

      <Footer/>
   </React.Fragment>
  )
}

export default Home
