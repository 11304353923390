import React, { FC } from 'react'
import './CbtDetails.scss'
import emptyAvarter from "./../../../../../Assets/images/user-empty-avatar.png";
import Table from 'react-bootstrap/esm/Table';

interface propsType {
    value: any;
    disable: boolean;
}

const CbtDetails:FC<propsType> = ({ value, disable }) => {
  const {
    workflow,
    surname,
    firstname,
    nin,
    photo,
    jobYear
  } = value

  const uploadPic = (e:any) => {
    // console.log(e)
  }

  console.log("VAL VAL", value)
  return (
    <div className="cbt-details my-5">
        {disable &&
          <div className="container-fluid">
            <div className="row d-flex justify-content-center">
              <div className="col-12 col-md-9 offset-md">
                <div className="card bg-white p-2">
                  <div className="table-responsive">
                  <Table bordered>
                    <tbody>
                      <tr>
                      <td colSpan={2} className="p-0">
                        <div className="card-header text-center text-dark bg-white p-1">
                          <div className="row">
                            <div className="col-2">
                               <img src={"logo.png"} alt="police" className="cbp-logo"/>
                            </div>
                            <div className="col-10">
                              <h2 className="text-dark font-weight-bolder cbt-title">Nigeria Police Force</h2>
                                <h6 className="text-dark">Department of Training and Development</h6>
                            </div>
                          </div>
                        </div>
                      </td>
                      </tr>
                      <tr>
                        <td colSpan={2}>
                          <div className="d-flex justify-content-center align-item-center">
                            <div className="cbt-profile-photo-content">
                            {photo
                              ? (<img src={`data:image/png;base64, ${photo}`} alt="" className="w-100 cbt-profile-photo"/>)
                              : (<img src={emptyAvarter} alt="" className="w-100 cbt-profile-photo" onClick={(e) => uploadPic(e)}/>)
                            }
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                          <td className="text-right">Status:</td>
                          {workflow.aptitude && workflow.aptitude.qualified === 1 && <td className="text-success font-bold font-weight-bold">Qualified</td>}
                          {workflow.aptitude && workflow.aptitude.qualified === 0 && <td className="text-danger font-bold font-weight-bold">Not qualified</td>}
                          {!workflow.aptitude && <td className="text-danger font-bold font-weight-bold">Not qualified</td>}
                        </tr>
                      {workflow.aptitude && workflow.aptitude.qualified === 0
                        ? (<>
                          <tr>
                              <td colSpan={2}>
                                  <div className="d-flex justify-content-center align-item-center py-4 reason">
                                      <div className="text-center">
                                          <h5>Dear {firstname + " " + surname}</h5>
                                          <p className="text-secondary">Sorry, you are not qualified for the CBT Exam stage of the { jobYear } Police admission exercise. </p>
                                          <h6>Reasons :</h6>
                                          <ul>
                                            {workflow.aptitude.reason.map((res:any, index:any) => {
                                              return <li key={index} className="text-danger">{index + 1}. {res}</li>
                                            })}
                                          </ul>
                                      </div>
                                  </div>
                              </td>
                          </tr></>
                          )
                        : (
                          <>
                          <tr>
                            <td className="text-right w-50">Applicant Name:</td>
                            <td className="font-weight-bold  w-50">{surname + " " + firstname} </td>
                          </tr>
                          <tr>
                          <td className="text-right  w-50">Exam No (NIN) :</td>
                          <td className="font-weight-bold  w-50">{nin} </td>
                        </tr>
                        <tr>
                          <td className="text-right  w-50">Password (Ref No):</td>
                          <td className="font-weight-bold  w-50">{workflow.aptitude.password} </td>
                        </tr>
                        <tr>
                          <td className="text-right  w-50">Date:</td>
                          <td className="font-weight-bold  w-50">{workflow.aptitude.cbtdate} </td>
                        </tr>

                        <tr>
                          <td className="text-right  w-50">Time:</td>
                          <td className="font-weight-bold w-50">{workflow.aptitude.cbttime}</td>
                        </tr>
                          <tr>
                          <td className="text-right w-50">Venue:</td>
                          <td className="font-weight-bold w-50">{workflow.aptitude.venue}</td>
                        </tr></>
                          )}
                    </tbody>
                  </Table>
                  </div>
                </div>
              </div>
            </div>
        </div>}
        <div className="text-center text-black cpb-note">
            <div>NOTE: This slip should be presented at the Examination Center.</div>
             <div> No admission into the examination hall without this slip.</div>
        </div>
    </div>
  )
}

export default CbtDetails
