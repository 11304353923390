import React, { FC } from 'react'
interface propsType {
    value: any;
    disable: boolean;
}
const AptitudeTest:FC<propsType> = ({ value, disable }) => {
  // eslint-disable-next-line no-unused-vars
  const { ssce } = value
  return (

    <div className="card mt-5">
    <div className="card-header cbt-heading text-light text-center font-weight-boder">
        <div className="c-header-crib-2">
            <span></span>
            <span></span>
            <span></span>
        </div>
       SSCE RESULT
        <div className="c-header-crib">
            <span></span>
            <span></span>
            <span></span>
        </div>
    </div>
    {disable === true && (
    <div className="card-body">
        <div className="container-fluid">
        <table className="table table-stripe">
           {
            Object.keys(ssce).map(function (element) {
              return (
                Object.keys(ssce[element]).length > 0 && (
                    <tr key={element}>
                    <td className="font-weight-bold"><h5>{ element }</h5></td>
                    <td>
                        <div className="row">

                            {Object.keys(ssce[element]).map((elements:any, index) => {
                              return (
                                <div key={index} className="col-md-3">
                                   <label>{ elements }</label>
                                   <input value={ ssce[element][elements]} className="form-control" readOnly></input>
                                </div>)
                            })}

                        </div>
                    </td>
                </tr>))
            }
            )
            }
            </table>
        </div>
    </div>
    )}
</div>
  )
}

export default AptitudeTest
