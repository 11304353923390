import { TOGGLE } from '../../Constants/SettingsConstants'

const sidebarReducer = (state = false, action: { type: any; data: any; }) => {
  switch (action.type) {
    case TOGGLE:
      return action.data
    default:
      return state
  }
}

export default sidebarReducer
