/* eslint-disable no-unused-vars */
import React, { Fragment, useCallback, useState, useEffect } from 'react';
import 'antd/dist/antd.min.css';
import './style.css';
import './Application.scss';
// eslint-disable-next-line no-unused-vars
import { createBrowserHistory, Path } from 'history'
import Footer from '../../../../Layout/Footer';
// eslint-disable-next-line no-unused-vars
import { RegisterHeader } from '../../Components/Index';
import { useForm } from 'react-hook-form';
import { message } from 'antd';
import applicantsService from '../../../../Services/applicantsService';
// eslint-disable-next-line no-unused-vars
import RegistrationFail from '../../Components/ConfirmationSlip/RegistrationFail/RegistrationFail';
import { Link, useNavigate } from "react-router-dom";
import userService from '../../../../Services/userService';
import noteService from '../../../../Services/noticeboard';
import ManagedRoutes from '../../../../Components/RoutesAccessControl/manageRoutes';

interface PropType {
  nin: any;
  formData: any;
  examNo: string;
  email: string;
  phone: string;
  candidateRole: string;
  metadata: {
    createdAt: number;
    dbRowId: number;
    expiredAt: number;
    hasCompletedAdmission: boolean;
    hasSaveToDB: boolean;
    isQualified: boolean;
    isQualifiedForNextWorkflow: boolean;
    pDisqualify: boolean;
    pipeline: string[];
  }
  dataset: unknown;
}

const StartApplcation = () => {
  const { register, handleSubmit, formState: { errors } } = useForm<PropType>();
  const [loading, setLoading] = useState(false);
  // const [loadingRRR, setLoadingRRR] = useState(false);
  const [alreadyRegistered] = useState(false);

  const [resData, setResData] = useState<any>(); // eslint-disable-next-line no-unused-vars
  const [boxErrorMessage, setBoxErrorMessage] = useState<string>('');
  // eslint-disable-next-line no-unused-vars
  const [boxSuccessMessage, setBoxSuccessMessage] = useState(false)
  // eslint-disable-next-line no-unused-vars
  const [ninValidated, setNinValidated] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [nimcData, setNimcData] = useState<any>();
  const [, setFormData] = useState({ nin: "" });
  // eslint-disable-next-line no-unused-vars
  const [rrrData, setRRRData] = useState<any>();

  // eslint-disable-next-line no-unused-vars
  const [response, setResponse] = useState<any>();
  // const [, setRrrDataRetrive] = useState<any>();
  // eslint-disable-next-line no-unused-vars
  const navigation = useNavigate();

  // const dataset = {
  //   nin: ninValidated,
  //   nimcData,
  //   rrrData,
  // }

  const submit = async (data: any) => {
    setFormData(data)
    // console.log(data)
    setLoading(true)

    await applicantsService.index(data)
      .then(response => {
        // console.log('RETURNED DATA', response)
        const responseData = response.data;
        setResponse(responseData.metadata);
        processNinData(responseData);
        setResData(responseData)
        // console.log('see', responseData.dataset);
        setBoxSuccessMessage(data)
        // console.log("ENCOUTER success", responseData);

        setLoading(false)

        // localStorage.setItem("pol", JSON.stringify(data))
      }).catch(error => {
        setLoading(false)

        // console.log("ENCOUTER ERROR", error?.message);

        if (error?.response?.data?.display === 'html') {
          setBoxErrorMessage(error?.response?.data?.message);
        } else {
          throwError(error?.response?.data?.message || 'NIN validation failed!')
        }
      })
  }

  const throwError = (mess: string = 'There was an error submitting application!') => {
    message.error({
      content: mess,
      style: {
        zIndex: '200000',
      },
    });
  }
  const [show, setShow] = useState<any>()

  const MenuController = async () => {
    await userService.getMenuCommand().then(
      response => {
        const res = response.data
        // console.log("RRR", res)
        setShow(res)
      }
    )
  }

  useEffect(() => {
    const commands = localStorage.getItem('MENU_COMMAND')
    if (commands !== null) {
      const { data } = JSON.parse(commands)
      setShow(data)
      // console.log('CachedData')
    } else {
      MenuController()
    }
  }, [])
  // useEffect(() => {
  // console.log('HEAD', resData)
  // }, [resData])
  const processNinData = useCallback(async (data: { [s: string]: unknown; } | ArrayLike<unknown>) => {
    // if (metadata && metadata.pipeline.includes('NIMC')) {
    //   console.log('NIMC is included in the pipeline');
    // }

    if (data && Object.entries(data).length === 0) {
      setNimcData(null);
      setNinValidated(false);
    } else {
      setNimcData(data)

      if (response.isQualified === false) {
        setNinValidated(false);
        navigation('/')
      } else if (response.isQualified) {
        setNinValidated(true)
        navigation('/verified-application')
        // console.log('some', response.isQualified)
      } else {
        setNinValidated(false);
        const rejectionReasons = response.registrationPipelineMessage?.map((err: any) => err.message).join('; ');
        // console.log('reject', rejectionReasons)
        navigation(`/rejection-slip?reasons=${encodeURIComponent(rejectionReasons)}`);
      }
    }
  }, [ninValidated, setNinValidated, setNimcData]);

  const location = {
    pathname: '/verified-application',
    state: {
      resData: { resData },
    }
  }
  // console.log('location', location);
  // eslint-disable-next-line no-unused-vars
  const [noticeData, setNoticeData] = useState<any>()
  const fetchNotice = async () => {
    await noteService.fetchNote().then((res) => {
      // console.log("fetchNotice", res)
      setNoticeData(res.data.dataset)
    }, error => {
      console.log("fetchNotice error", error)
    })
  }

  useEffect(() => {
    fetchNotice()
  }, [])
  const navigate = useNavigate()
  return (
    <Fragment>
      <div className='main-app'>
        <RegisterHeader />
        {
          show?.registrationForm.switch === 1 &&

          <main role="main" className="container">
            {
              boxErrorMessage &&
              <RegistrationFail message={boxErrorMessage} clearErrorBox={() => setBoxErrorMessage('')} />
            }
            <div>
              <section className={`card border-0 rounded${boxErrorMessage ? ' d-none' : ''}`}>
                <div className="bg-white">
                  <div className="section-container">
                    <div className="col-md-12 text-white text-center card-header section-header-bg">
                      {/* <h1>Your National Identification Number (NIN) </h1> */}
                      <h1>Registration (Step 1 of 2)</h1>
                    </div>
                  </div>
                </div>

              </section>
              <div>
                <form onSubmit={handleSubmit(submit)}>
                  <div className="row p-3 justify-content-center">
                    <div className="form-group col-md-6 padleft">
                      <div className="text-center font-weight-bold text-info p-2"><i
                        className="fa fa-default"></i>Please provide the required information to continue.
                      </div>
                      <br />
                      <div className="form-group">
                        <input
                          type="text"
                          {...register("nin")}
                          className={`custom-form-control form-control ${ninValidated ? 'is-valid' : 'is-invalid'}`}
                          id="nin"
                          placeholder="NIN No"
                        />
                        {!ninValidated &&
                          <small className="text-info">Please provide a valid NIN</small>}
                      </div>
                      <div className="form-group">

                      </div>
                      <div className="form-group">
                        <input
                          type="email"
                          {...register("email", { required: "Email cannot be empty" })}
                          className={`custom-form-control form-control ${ninValidated ? 'is-valid' : 'is-invalid'}`}
                          id="niemailn"
                          placeholder="Your email address"
                        />
                        {errors.email && <div className='text-danger'>{errors.email.message}</div>}
                      </div>
                      <div className="form-group">
                        <input
                          type="number"
                          {...register("phone", { required: "Phone cannot be empty" })}
                          className={`custom-form-control form-control ${ninValidated ? 'is-valid' : 'is-invalid'}`}
                          id="phone"
                          placeholder="Your phone no e.g 08034008900 (without country code)"
                        />
                        {errors.phone && <div className='text-danger'>{errors.phone.message}</div>}
                      </div>
                      <div className={'mb-3'}>
                        <select className={`custom-form-control form-control ${ninValidated ? 'is-valid' : 'is-invalid'}`} {...register("candidateRole")} >
                          <option value={""}>-- Please choose job title --</option>
                          <option value={"generalDuty"}>General Duty</option>
                          <option value={"specialist"}>Specialist</option>
                        </select>
                        {errors.candidateRole && <div className='text-danger'>{errors.candidateRole.message}</div>}
                        <small className="text-info">Please choose carefully, upon verification, you will not be allowed to change the option</small>
                      </div>
                      <div className="text-center">
                        <button
                          className={ninValidated ? `btn btn-success font-weight-bold` : `btn btn-info font-weight-bold`}
                          type="submit">
                          {ninValidated && <i className="fa fa-check text-light"></i>}
                          {ninValidated ? ' Verified' : 'Verify'}
                        </button>
                        {loading && <div style={{ marginTop: 10 }}
                          className="spinner-border spinner-border-sm"></div>}
                      </div>

                      {alreadyRegistered &&
                        <small className="error-value">Applicant already registered. Redirecting to pint
                          slip...</small>}
                    </div>
                  </div>
                  <div className="w-100 text-center">
                    <div className="text-center">
                      <p className="font-weight-bold text-info p-2">
                        Application into Nigeria Police Force (NPF) requires National Identification Number (NIN), <br />
                        by providing the NIN, you have given Nigeria Police Force the right to get your Bio data <br />
                        i.e. Your profile will be validated on this page before you can proceed to the application form. <br />
                        Thank you.
                      </p>
                    </div>
                    {response && (
                      <div>
                        {response.hasCompletedAdmission
                          ? (
                            <div>
                              {!response.isQualified
                                ? (
                                  <div className="alert alert-danger">{response.registrationPipelineMessage}</div>
                                  )
                                : (
                                  <button>Slip</button>
                                  )}
                            </div>
                            )

                          : (<div className="alert alert-success">
                            <Link to='/verified-application' state={{ resData: { resData } }} ><button className={'btn btn-success'}>PROCEED TO APPLICATION FORM</button></Link></div>)
                        }
                      </div>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </main>}
        {(show !== 0 && show?.registrationForm.switch === 0)
          ? <>
            <div className='container pt-4'>
              <div dangerouslySetInnerHTML={{ __html: noticeData?.body }} />
            </div>
          </>
          : <></>
        }
        <Footer />
      </div>

    </Fragment>
  );
}
export default StartApplcation
// (noticeData !== 0 && noticeData.map((notice: any, index: number) => {
//               return <div className="" key={index}>
//               <div className="row h-100 justify-content-center">
//                 <div className="col-md-12 my-auto">
//                   <div className="card py-0 shadow-0 fw-bold rounded-3">
//                     <div className='card-header text-center'></div>
//                     <div className="card-body text-start homedisablecard">
//                       <div className='container pt-5'>
//                         <h2 className="text-center border-bottom text-white mt-3">{notice?.subject}</h2>
//                         <div dangerouslySetInnerHTML={{ __html: notice?.body }}></div>
//                         <div className='text-center mt-3 pb-5'>
//                           <Link to={notice?.button_link}><button className='btn btn-danger w-50'><span className='fw-bold'>{notice?.button_label}</span></button></Link>
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//             }))
