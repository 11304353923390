
import React, { Fragment, useCallback, useRef, useEffect, useState } from 'react'
import { message } from 'antd'
import axios from 'axios'
import { useForm } from 'react-hook-form'
import 'antd/dist/antd.min.css'
import './style.css'
import './Application.scss'
import subject from "../../../../Data/subject.json"

import { useDropzone } from 'react-dropzone'

import states from '../../../../Data/state.json'
import lgas from '../../../../Data/lga.json'
import RegistrationFail from '../../Components/ConfirmationSlip/RegistrationFail/RegistrationFail'
import { getAgeFromYear } from '../../../../Services/getAgeFromYear'
import { AllowAccessToApplication, FileToUrl } from '../../../../Helpers'
// @ts-ignore
import { BASE_URL_ADDR, BASE_URL_OCR } from '../../../../Constants/BaseURL'
import { Base64AppendString } from '../../../../Constants/Base64AppendString'
import { useLocation, useNavigate } from 'react-router-dom';
// import Loader from '../../../../Components/Loader/Loader';
import { Loader1, Loader2 } from '../../../../Components'
import ConfirmationAlert from '../../../../Components/ConfirmationAlert/ConfirmationAlert';

type DocumentsType = {
  // waec: File | string;
  // waec2: any;
  // gce: File | string;
  // gce2: any;
  // neco: File | string;
  // neco2: any;
  // nabteb: File | string;
  // nabteb2: any;
  ssce: File | string;
  ssce2: File | string;
  birthCertificate: File | string;
  indigneCerificate: File | string;
  professionalCertificate: File | string;
}

const emptyFormData = {
  nin: '',
  surname: '',
  firstname: '',
  middlename: '',
  gender: '',
  maritalStatus: '',
  dob: '',
  contactPhone: '+234',
  nationality: '',
  stateOfPermanent: '',
  lgaOfPermanent: '',
  streetAddressOfPermanent: '',
  phone: '+234',
  phone1: '+234',
  phone2: '+234',
  email: '',
  postalAddress: '',
  stateOfResidence: '',
  lgaOfResidence: '',
  streetAddressOfResidence: '',
  nextOfKinName: '',
  nextOfKinPhone: '',
  nextOfKinEmail: '',
  nextOfKinGender: '',
  nextOfKinRelationship: '',
  resume: '',
  waec: false,
  gce: false,
  neco: false,
  nabteb: false,
  birthCertificate: false,
  indigneCerificate: false,
  professionalCertificate: false,
  firstSittingRegNo: '',
  firstSitting: {
    subject1: 'Mathematics',
    grade1: '',
    subject2: 'English Language',
    grade2: '',
    subject3: '',
    grade3: '',
    subject4: '',
    grade4: '',
    subject5: '',
    grade5: '',
    subject6: '',
    grade6: '',
    subject7: '',
    grade7: '',
    subject8: '',
    grade8: '',
    subject9: '',
    grade9: ''
  },
  secondSitting: {
    subject1: '',
    grade1: '',
    subject2: '',
    grade2: '',
    subject3: '',
    grade3: '',
    subject4: '',
    grade4: '',
    subject5: '',
    grade5: '',
    subject6: '',
    grade6: '',
    subject7: '',
    grade7: '',
    subject8: '',
    grade8: '',
    subject9: '',
    grade9: ''
  },
  secondSittingRegNo: ''

}

const documentsMapper = (type: string) => {
  switch (type) {
    // case 'waec':
    //   return 'WAEC';
    // case 'gce':
    //   // code block
    //   return 'GCE'
    // case 'neco':
    //   // code block
    //   return 'Neco';
    // case 'nabteb':
    //   // code block
    //   return 'NABTEB';
    // case 'waec1':
    //   return 'WAEC1';
    // case 'gce1':
    //   // code block
    //   return 'GCE1'
    // case 'neco1':
    //   // code block
    //   return 'Neco1';
    // case 'nabteb1':
    //   // code block
    //   return 'NABTEB1';
    case 'ssce':
      // code block
      return 'SSCE'
    case 'ssce2':
      // code block
      return 'SSCE2'
    case 'birthCertificate':
      // code block
      return 'Birth Certificate/Age Declaration'
    case 'indigneCerificate':
      // code block
      return 'LGA/ indigene certificate '
    case 'professionalCertificate':
      return 'Professional certificate'
    default:
    // code block
  }
}

const emptyDocuments = {
  // waec: '',
  // gce: '',
  // neco: '',
  // nabteb: '',
  // waec1: '',
  // gce1: '',
  // neco1: '',
  // nabteb1: '',
  ssce: '',
  ssce2: '',
  birthCertificate: '',
  indigneCerificate: '',
  professionalCertificate: ''
}

const loaders = {
  submit: false,
  nimc: false,
  jamb: false,
  step1: false,
  step2: false,
}

const Application = (data: any) => {
  // const location = useLocation();
  // const queryParams = new URLSearchParams(location.search);
  // const resData = JSON.parse(queryParams.get('resData') || '{}');
  //
  // console.log('resData:', resData);
  const newPhotoInputRef = useRef<any>(null)

  const [formData, setFormData] = useState(emptyFormData)
  const [documents, setDocuments] = useState<DocumentsType | any>(emptyDocuments)
  const [loading, setLoading] = useState(loaders)
  const [consent, setConsent] = useState(false)
  // eslint-disable-next-line no-unused-vars
  const [ninValidated, setNinValidated] = useState(false)
  const [nimcData, setNimcData] = useState<any>()
  // eslint-disable-next-line no-unused-vars
  const [ssce, setSsceData] = useState<any>(null)
  const [ssceLoading, setSsceLoading] = useState<boolean>(false)
  const navigate = useNavigate()

  // eslint-disable-next-line no-unused-vars
  const [, setOpenGuildlines] = useState<boolean>(false)

  // const [subjects, setSubjects] = useState<string[]>([]);
  // eslint-disable-next-line no-unused-vars
  const [grades, setGrades] = useState<string[]>([])
  const [documentError, setDocumentError] = useState<any>()
  const [resultError, setResultError] = useState<string>()
  const [examNoError, setExamNoError] = useState<string>()
  const [submissionSuccess] = useState(false)
  const [alreadyRegistered] = useState(false)
  const [boxErrorMessage, setBoxErrorMessage] = useState<string>('')
  const [radioForm, setRadioForm] = useState<string>('')

  const [currentSitting, setCurrentSitting] = useState<string>('')
  // eslint-disable-next-line no-unused-vars
  const [sittings, setSittings] = useState<number>(0)

  // number of selected files
  const [fileCount, setFileCount] = useState(0)

  const [ssceFormRow, setSsceFormRow] = useState([
    { examYear: "", examNo: "", examType: "" }
  ])

  const history = useNavigate()

  const isNimcPhotoValid = nimcData?.photo?.length > 10

  // THE RESULT FORM INPUT
  // eslint-disable-next-line no-unused-vars
  const [firstSittingForm, setFirstSittingForm] = useState({})
  // eslint-disable-next-line no-unused-vars
  const [secondSittingForm, setSecondSittingForm] = useState({})

  const registrationCompleted = () => {
    message.success({
      content: 'Application was successful!',
      style: {
        zIndex: '200000'
      }
    })
  }

  // const verifySsce = (data: any) => {
  //   const mathsFirst = data.firstSitting.subject1
  //   const mathsSecond = data.secondSitting.subject1

  //   // const englishFirst = data.firstSitting.subject2
  //   // const englishSecond = data.secondSitting.subject2
  //   // const englishFirstGrade = data.firstSitting.subject2
  //   // const englishSecondGrade = data.secondSitting.subject2

  //   console.log(mathsFirst, mathsSecond)
  // }

  const throwError = (mess: string = 'There was an error submitting application!') => {
    message.error({
      content: mess,
      style: {
        zIndex: '200000'
      }
    })
  }

  const changeConsent = useCallback((e: { target: { checked: boolean | ((prevState: boolean) => boolean); }; }) => {
    setConsent(e.target.checked)
  }, [setConsent])

  const [currentDocument, setCurrentDocument] = useState<string | File | any>('')

  const changeCurrentDocument = (e: any) => {
    setCurrentDocument(e.target.value)
    setDocumentError(null)
  }

  const removeDocument = useCallback((type: any) => {
    setDocuments({
      ...documents,
      [type]: ''
    })
    setFormData({
      ...formData,
      [type]: false
    })
  }, [setDocuments, documents, setFormData, formData])
  const { handleSubmit, register, setValue, watch, formState: { errors } } = useForm<any>()
  // const selectedV = watch("candidateRole");
  const getDocumentsUploadProgress = useCallback(() => {
    const doc = documents as DocumentsType
    let successDoc = 0 //  No of document required uploaded
    let progress = 0

    let wasscProgress = 0

    Object.keys(doc).forEach((key: string) => {
      if (doc[key as keyof DocumentsType] !== '') {
        if (key === 'waec' || key === 'neco' || key === 'gce' || key === 'nabteb') {
          if (wasscProgress === 0) {
            wasscProgress++
            successDoc++
          }
        } else {
          successDoc++
        }
      }
    })

    if (successDoc > 0) {
      if (radioForm === 'generalDuty') {
        progress = (successDoc / 3) * 100
      } else {
        progress = (successDoc / 4) * 100
      }
    }
    return Math.round(progress) // Round off to the nearest whole number
  }, [documents])

  // eslint-disable-next-line no-unused-vars

  const updateNimcPhoto = (photoUrl: string) => {
    photoUrl = photoUrl.split('base64,')[1]
    setNimcData({ ...nimcData, photo: photoUrl })
  }

  const changeNewPhoto = async (e: any) => {
    FileToUrl(e.target.files[0], updateNimcPhoto)

    const dataFileUp = {
      document: e.target.files[0],
      nin: resData.resData.nimc.nin,
      documentType: currentDocument
    }

    await fileUploader(dataFileUp)
  }

  const onDrop = async (acceptedFiles: any) => {
    // Do something with the files
    //  Allowed file extension types
    const requiredFileTypes = ['jpg', 'png', 'jpeg', 'pdf']
    //  Validate uploaded document
    const validate = validateType(acceptedFiles[0].type, requiredFileTypes)

    if (validate) {
      setFileCount(fileCount + 1)

      /*   if a document has already been added save it
       *   with a number so that it will be posible to
       *   upload more than one document
    */
      if (documents[currentDocument] !== '') {
        if (currentDocument === 'neco' || currentDocument === 'waec' || currentDocument === 'gce' || currentDocument === 'nabteb') {
          setDocuments({
            ...documents,
            ssce1: acceptedFiles[0]
          })
        } else {
          setDocuments({
            ...documents,
            [currentDocument]: acceptedFiles[0]
          })
        }
        const currentDoc = (currentDocument === 'neco' || currentDocument === 'waec' || currentDocument === 'gce' || currentDocument === 'nabteb') ? "ssce1" : currentDocument + '1'
        // section start upload files

        const dataFileUp = {
          document: acceptedFiles[0],
          nin: resData.resData.nimc.nin,
          documentType: currentDoc
        }
        await fileUploader(dataFileUp)
      } else {
        const currentDoc = (currentDocument === 'neco' || currentDocument === 'waec' || currentDocument === 'gce' || currentDocument === 'nabteb') ? "ssce" : currentDocument

        setDocuments({
          ...documents,
          [currentDoc]: acceptedFiles[0]
        })

        setFormData({
          ...formData,
          [currentDocument]: true
        })

        const dataFileUp = {
          document: acceptedFiles[0],
          nin: resData.resData.nimc.nin,
          documentType: currentDoc
        }

        await fileUploader(dataFileUp)
      }
    } else {
      setDocumentError(`Please, upload either of these file types; ${requiredFileTypes}`)
    }
  }

  const fileUploader = async (data: { document: any, nin: number, documentType: string }) => {
    const headers = {
      'Content-Type': 'multipart/form-data',
      Accept: 'application/json'
    }
    await axios.post(`${BASE_URL_ADDR}/recruitment/upload-credentials?module=portal&admissionId=3`, data, {
      headers
    })
      .then(async (res) => {
        message.success({
          content: 'File saved!',
          style: {
            zIndex: '200000'
          }
        })

        if (sittings === 2 && fileCount >= 3) {
          await initOcr()
        }

        const ossce = ['ssce', 'ssce2']
        if (sittings === 1 && ossce.includes(currentDocument)) {
          await initOcr()
        }
      }, (error) => {
        throwError(error.response.data.message)
      })
  }

  const initOcr = async () => {
    await axios.get(`${BASE_URL_OCR}/ocr-server/request-for-extraction?nin=${resData.resData.nimc.nin}&forceExtraction=1`)
      .then((res) => {
        message.success({
          content: 'proccess initiated',
          style: {
            zIndex: '200000'
          }
        })
      }, (error) => {
        throwError(error.response.data.message)
      })
  }

  const generateOcr = async () => {
    setSsceLoading(true)
    setTimeout(async () => {

      // await axios.get(`${BASE_URL_OCR}/ocr-server/extract-document?nin=${profileData.dataset.nimc.nin}`)
      //   .then((res) => {
      //     message.success({
      //       content: 'Verification successful',
      //       style: {
      //         zIndex: '200000'
      //       }
      //     })

      //     setSsceData(res.data)
      //     setSsceLoading(false)
      //   }, (error:any) => {
      //     setSsceLoading(false)
      //     console.log('OCR ERROR', error)
      //     throwError(error.response.data.message)
      //   })
    }, 7000)
  }

  const location = useLocation();
  const { resData } = location.state as { resData: any };
  //   console.log("location state", location.state);
  // const ninValidation = (location.state !== null && location.state !== undefined) ? (location.state as any)?.resData : null;

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

  const validateType = (type: string, accepedTypes: any = null) => {
    const trailSlashIndex = type.indexOf('/')

    const fileType = type.slice(trailSlashIndex + 1)

    if (accepedTypes.indexOf(fileType) === -1 && accepedTypes.length > 0) {
      return false
    }
    return true
  }

  // console.log("profileData data element", profileData.dataset.nimc)

  const onSubmit = async (data: any, e: any) => {
    // console.log('Form data', data)
    const nimc = resData.resData.nimc
    // const firstSitting = ssce.collatedSSCE.firstSitting && Object.assign({}, ...ssce.collatedSSCE.firstSitting.map((result: any, index: any) => {
    //   return Object.assign({ ['subject' + index]: result.subject, ['grade' + index]: result.grade }, index)
    // }))
    //
    // const secondSitting = Object.assign({}, ...ssce.collatedSSCE.secondSitting.map((result: any, index: any) => {
    //   return Object.assign({ ['subject' + index]: result.subject, ['grade' + index]: result.grade }, index)
    // }))

    const newData = {
      ...data,
      firstSitting: { ...firstSittingForm },
      secondSitting: { ...secondSittingForm },
      sscedetails: ssceFormRow,
      nin: nimc.nin,
      firstSittingRegNo: '',
      secondSittingRegNo: '',
      birthCertificate: false,
      indigneCerificate: false,
      professionalCertificate: false,
      waec: false,
      gce: false,
      neco: false,
      nabteb: false
    }
    // verifySsce(newData)
    setDocumentError('')
    setResultError('')
    setExamNoError('')
    // if (getDocumentsUploadProgress() !== 100) {
    //   return setDocumentError('Please upload atleast three documents')
    // }

    if (!isNimcPhotoValid) {
      throwError('Please, upload a photo')
      return
    }

    setLoading({
      ...loading,
      submit: true,
      step1: true,
    })

    submitApplication({ formData: newData })
  }
  const changeFormData = (e: any) => {
    const check = e.target.name.substring(0, 4)

    if (check === 'grad' || check === 'subj') {
      const prevFormData = { ...formData } as any

      const sitt = prevFormData[currentSitting]
      sitt[e.target.name] = e.target.value

      const finalData = {
        ...prevFormData,
        [currentSitting]: sitt
      }

      return setFormData(finalData)
    }
    const form = { ...formData }

    setFormData({
      ...form,
      [e.target.name]: e.target.value
    })
  }

  const redirectToPrintSlip = () => {
    navigate(`/print-slip`, { replace: true })
  }

  const submitApplication = async (data: { formData: any }) => {
    setLoading({
      ...loading,
      submit: true
    })
    await axios({
      method: 'POST',
      url: `${BASE_URL_ADDR}/recruitment/validate-submission`,
      headers: {
        'content-type': 'application/json',
        Accept: 'application/json'
      },
      data
    }).then(response => {
      setTimeout(() => {
        setLoading({
          ...loading,
          step1: false,
          step2: true,
        })
      }, 10000)

      setTimeout(() => {
        setLoading({
          ...loading,
          step1: false,
          step2: false,
        })

        return ConfirmationAlert(
          () => {
            registrationCompleted()
            setTimeout(redirectToPrintSlip, 1000)
          },
          'Application Completed!',
          'Congratulations your application is successful, Proceed to print your confirmation slip.',
          'Close'
        )
      }, 20000)
    }).catch(error => {
      if (error?.response?.data?.display === 'html') {
        setBoxErrorMessage(error?.response?.data?.message);
      } else {
        throwError(error?.response?.data?.message)
      }
    }).finally(() => {
      setLoading({
        ...loading,
        submit: false
      })
    })
  }

  // eslint-disable-next-line no-unused-vars
  const [boxSuccessMessage, setBoxSuccessMessage] = useState(false)

  const allowRegistration = () => {
    if (!AllowAccessToApplication()) {
      history('/')
    }
  }

  useEffect(() => {
    if (nimcData) {
      const formatDOB = nimcData.birthdate.split('-').reverse().join('-')
      setValue('surname', nimcData.surname, { shouldValidate: true })
      setValue('firstname', nimcData.firstname, { shouldValidate: true })
      setValue('dob', formatDOB, { shouldValidate: true })
      setValue('age', getAgeFromYear(new Date(formatDOB)), { shouldValidate: true })
      setValue('gender', nimcData.gender, { shouldValidate: true })
      setValue('phone', nimcData.telephoneno, { shouldValidate: true })
      setValue('phone', nimcData.telephoneno, { shouldValidate: true })
      setValue('email', nimcData.email, { shouldValidate: true })
      setValue('nextOfKinName', nimcData.nok_surname + ' ' + nimcData.nok_firstname, { shouldValidate: true })
      setValue('maritalStatus', nimcData.maritalstatus, { shouldValidate: true })
    }
  }, [nimcData])

  useEffect(() => {
    allowRegistration()
  }, [])

  useEffect(() => {
    setNinValidated(true)
  }, [])

  useEffect(() => {
    if (resData) {
      setRadioForm(resData.resData.formData.candidateRole)
      setBoxSuccessMessage(resData.resData.nimc)
      setNimcData(resData.resData.nimc)
    }
  }, [])

  function handleFirstSittingChange (evt: any) {
    const value = evt.target.value;
    const value2 = evt.target.value.subject1;
    console.log('HHH', value2);
    setFirstSittingForm({
      ...firstSittingForm,
      [evt.target.name]: value
    });
  }

  function handleSecondSittingChange (evt: any) {
    const value = evt.target.value;

    console.log('HHH', value);
    setSecondSittingForm({
      ...secondSittingForm,
      [evt.target.name]: value
    });
  }
  // eslint-disable-next-line no-unused-vars
  const [displayForm, setDisplayForm] = useState(false);

  const selectedValue = watch("skillTitle");

  useEffect(() => {
    if (selectedValue !== '' || radioForm === 'generalDuty') {
      setDisplayForm(true);
    } else {
      setDisplayForm(false);
    }
  }, [selectedValue, radioForm]);
  const handleAddFormRow = (e: any) => {
    e.preventDefault()
    const values = [...ssceFormRow];
    values.push({
      examNo: "",
      examType: "",
      examYear: "",
    });
    setSsceFormRow(values);
  };

  const handleRemoveFormRow = (index: number) => {
    const values = [...ssceFormRow];
    values.splice(index, 1);
    setSsceFormRow(values);
  };
  const handleFormRowInputChange = (index: number, event: { target: { name: string; value: string } }) => {
    const values: any[] = [...ssceFormRow];
    const updatedValue: any = event.target.name;
    values[index][updatedValue] = event.target.value;
    setSsceFormRow(values);
  };

  const validateFormRow = () => {
    if (ssceFormRow[0].examType && ssceFormRow[0].examNo && ssceFormRow[0].examYear) {
      return true
    } else {
      return false
    }
  }

  const subArray: any = Object.keys(subject.examSubject)

  return (
    <Fragment>
      <div className='main-app'>
        <main role="main" className="container">

          {/* section form */}

          <form onSubmit={handleSubmit(onSubmit)}>
            {
              !submissionSuccess && !alreadyRegistered &&
              <>

                {
                  boxErrorMessage && <RegistrationFail message={boxErrorMessage} clearErrorBox={() => setBoxErrorMessage('')} />
                }
                {
                  (ninValidated && !boxErrorMessage) && <>
                    <section className={`card border-0 rounded${(!ninValidated || boxErrorMessage) ? ' d-none' : ''}`} id="doc">
                      <div className='bg-white'>
                        <div className='section-container'>
                          <div className="col-md-12 w-100 subheading text-white text-center card-header section-header-bg">
                            <h1>{radioForm === 'generalDuty' ? "General Duty Constable Application Form" : "Specialist (Artisan) Constable Application Form"}</h1>
                          </div>
                        <div className='col m-3'><h3>Job Role: {radioForm === 'generalDuty' ? "General Duty" : "Specialities"}</h3></div>
                              <div className={`${radioForm !== 'generalDuty' ? 'd-block' : 'd-none'} col`}>
                                <label>Speciality</label>
                                <select className='form-control custom-form-control' {...register("skillTitle")} >
                                  <option value={""}>--Please choose speciality--</option>
                                  <option value={"medicalAssistant"}>Medical Assistant</option>
                                  <option value={"bandSection"}>Band Section</option>
                                  <option value={"communications"}>Communications Specialist</option>
                                  <option value={"infoTech"}>Information Technology Specialist</option>
                                  <option value={"driver"}>Driver</option>
                                  <option value={"mechanic"}>Mechanic</option>
                                  <option value={"marine"}>Marine</option>
                                  <option value={"plumber"}>Plumber</option>
                                  <option value={"mason"}>Mason</option>
                                  <option value={"painter"}>Painter</option>
                                  <option value={"tiler"}>Tiler</option>
                                  <option value={"electrician"}>Electrician</option>
                                  <option value={"welder"}>Welder</option>
                                  <option value={"carpenter"}>Carpenter</option>
                                  <option value={"carpenter"}>Carpenter</option>
                                  <option value={"veterinaryAssistant"}>Veterinary Assistant </option>
                                  <option value={"printerMachineOperator"}>Printer Machine Operator </option>
                                </select>
                          </div>
                        </div>
                      </div>
                    </section>
                  </>
                }
                {
                  !boxErrorMessage && <>
                    <section className={`card border-0 rounded${boxErrorMessage ? ' d-none' : ''} ${!ninValidated ? ' d-none' : ''} ${!displayForm ? ' d-none' : 'd-block'}`} id="personal">
                      <div className="bg-white">

                        <div className="section-container">
                          <div className="section-item-container">
                          </div>
                          <div className="col-md-12 subheading text-white text-center card-header section-header-bg">
                            <h1>Personnal Information</h1>
                          </div>
                          <div className="section-item-container">
                          </div>
                        </div>
                        <input ref={newPhotoInputRef} type="file" onChange={changeNewPhoto} style={{ display: 'none' }} name="new-photo" id="new-photo" />
                        <div className={`w-0 section-persional-container-pro p-2${!ninValidated ? ' d-none' : ''}`}>
                          <div className="section-persional-pro shadow nimc-photo text-center" onClick={() => { newPhotoInputRef.current.click(); setCurrentDocument('photo') }}>
                            <img src={(nimcData && nimcData.photo) ? `${Base64AppendString}${nimcData.photo}` : 'asset/images/user.png'} className="w-75" />
                          </div>
                        </div>

                        <div className="row p-3">
                          <div className={`col-md-6${!ninValidated ? ' d-none' : ''}`}>
                            <div className="form-group padright">
                              <label className="control-label" htmlFor="surname">Surname<span id="compulsory">*</span></label>
                              <div className="input-group">
                                <div className="input-group-prepend">
                                  <div className="input-group-text"><i className="fa fa-user text-default"></i></div>
                                </div>
                                <input
                                  type="text"
                                  className="custom-form-control form-control"
                                  id="surname"
                                  placeholder="Surname"
                                  {...register('surname', { required: 'surname field is required' })}
                                />
                              </div>
                            </div>
                          </div>
                          <div className={`col-md-6${!ninValidated ? ' d-none' : ''}`}>
                            <div className="form-group padleft">
                              <label className="control-label" htmlFor="firstname">First Name<span id="compulsory">*</span></label>
                              <div className="input-group">
                                <div className="input-group-prepend">
                                  <div className="input-group-text"><i className="fa fa-user text-default"></i></div>
                                </div>
                                <input
                                  type="text"
                                  className="custom-form-control form-control"
                                  id="firstname"
                                  placeholder="Firstname"
                                  disabled
                                  {...register('firstname', { required: 'firstname field is required' })}
                                />
                              </div>
                            </div>
                          </div>
                          ``
                          <div className={`col-md-6${!ninValidated ? ' d-none' : ''}`}>
                            <div className="form-group padright">
                              <label className="control-label" htmlFor="middlename">Other Names</label>
                              <div className="input-group">
                                <div className="input-group-prepend">
                                  <div className="input-group-text"><i className="fa fa-user text-default"></i></div>
                                </div>
                                <input
                                  type="text"
                                  className="custom-form-control form-control"
                                  id="middlename"
                                  placeholder="Middle Name"
                                  {...register('middlename')}
                                />
                              </div>
                            </div>
                          </div>

                          <div className={`form-group col-md-6 padleft${!ninValidated ? ' d-none' : ''}`}>
                            <label className="control-label" htmlFor="gender">Gender<span id="compulsory">*</span></label>
                            <div className="input-group">
                              <div className="input-group-prepend">
                                <div className="input-group-text"><i className="fa fa-transgender text-default"></i></div>
                              </div>
                              <select
                                className="form-control custom-form-control"
                                id="gender"
                                disabled
                                {...register('gender', { required: 'gender field is required' })}
                              >
                                <option value="m">Male</option>
                                <option value="f">Female</option>
                              </select>
                            </div>
                          </div>

                          <div className="form-group col-md-6 padleft">
                            <label className="control-label" htmlFor="phone">Phone number<span id="compulsory">*</span></label>
                            <div className="input-group">
                              <div className="input-group-prepend">
                                <div className="input-group-text"><i className="fa fa-phone text-default"></i></div>
                              </div>
                              <input
                                type="number"
                                className="custom-form-control form-control"
                                id="phone"
                                placeholder="Phone number"
                                {...register('phone', { required: 'phone field is required' })}
                              />
                            </div>
                          </div>

                          <div className="form-group col-md-6 padleft">
                            <label className="control-label" htmlFor="phone">Phone number<span id="compulsory">*</span></label>
                            <div className="input-group">
                              <div className="input-group-prepend">
                                <div className="input-group-text"><i className="fa fa-phone text-default"></i></div>
                              </div>
                              <input
                                type="number"
                                className="custom-form-control form-control"
                                id="phone2"
                                placeholder="Phone number 2"
                                min="0"
                                {...register('phone2')}
                              />

                              {errors.phone2 &&
                                <small className="error-value">Please, provide an optional phone number</small>}
                            </div>
                          </div>

                          <div className="form-group col-md-6 padleft">
                            <label className="control-label" htmlFor="email">Email<span
                              id="compulsory">*</span></label>
                            <div className="input-group">
                              <div className="input-group-prepend">
                                <div className="input-group-text"><i
                                  className="fa fa-envelope text-default"></i></div>
                              </div>
                              <input
                                className="custom-form-control form-control"
                                id="email"
                                placeholder="Email"
                                disabled={false}
                                {...register('email', {
                                  required: true,
                                  pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i
                                })}
                              />
                            </div>
                            {errors.email &&
                              <small className="error-value">Please, provide a valid email</small>}
                          </div>

                          <div className={`form-group col-md-6 padright${!ninValidated ? ' d-none' : ''}`}>
                            <label className="control-label" htmlFor="marital_status">Marital Status</label>
                            <div className="input-group">
                              <div className="input-group-prepend">
                                <div className="input-group-text"><i className="fa fa-heart text-default"></i></div>
                              </div>
                              <select
                                className="form-control custom-form-control"
                                id="maritalStatus"
                                {...register('maritalStatus', { required: 'maritalStatus field is required' })}
                              >
                                <option value="">Select Marital Status</option>
                                <option value="single">Single</option>
                                <option value="married">Married</option>
                              </select>
                            </div>
                            {/* {errors.maritalStatus && <small className="error-value">This field is required</small>} */}
                          </div>

                          <div className={`form-group col-md-6 padleft${!ninValidated ? ' d-none' : ''}`}>
                            <label className="control-label" htmlFor="dob">Date of Birth<span id="compulsory">*</span></label>
                            <div className="input-group">
                              <div className="input-group-prepend">
                                <div className="input-group-text"><i className="fa fa-clock text-default"></i></div>
                              </div>
                              <input
                                className="custom-form-control form-control"
                                id="dob"
                                placeholder="dob"
                                disabled
                                {...register('dob', { required: 'Date of Birth field is required' })}
                              />
                            </div>
                          </div>

                          <div className={`form-group col-md-6 padleft${!ninValidated ? ' d-none' : ''}`}>
                            <label className="control-label" htmlFor="dob">Age<span id="compulsory">*</span></label>
                            <div className="input-group">
                              <div className="input-group-prepend">
                                <div className="input-group-text"><i className="fa fa-clock text-default"></i></div>
                              </div>
                              <input
                                className="custom-form-control form-control"
                                id="age"
                                placeholder="age"
                                disabled
                                {...register('age', { required: 'age of Birth field is required' })}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>

                    <section className={`card border-0  rounded${(!ninValidated || boxErrorMessage) ? ' d-none' : ''} ${!displayForm ? ' d-none' : 'd-block'}`} id="nxtkin">
                      <div className="bg-white">

                        <div className="section-container">
                          <div className="section-item-container">
                          </div>
                          <div className="col-md-12 subheading text-white text-center card-header section-header-bg">
                            <h1>Next of kin</h1>
                          </div>
                          <div className="section-item-container">
                          </div>
                        </div>

                        <div className="row p-2">

                          <div className="col-md-6">
                            <div className="form-group padright">
                              <label className="control-label" htmlFor="kin_name">Name<span id="compulsory">*</span></label>
                              <div className="input-group">
                                <div className="input-group-prepend">
                                  <div className="input-group-text"><i className="fa fa-user text-default"></i></div>
                                </div>
                                <input
                                  type="text"
                                  className="custom-form-control form-control"
                                  id="nextOfKinName"
                                  {...register('nextOfKinName', { required: 'Next Of Kin Name field is required' })}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group padleft">
                              <label className="control-label" htmlFor="kin_phone">Phone<span id="compulsory">*</span></label>
                              <div className="input-group">
                                <div className="input-group-prepend">
                                  <div className="input-group-text"><i className="fa fa-phone text-default"></i></div>
                                </div>
                                <input
                                  type="number"
                                  className="custom-form-control form-control"
                                  id="nextOfKinPhone"
                                  {...register('nextOfKinPhone', { required: true, minLength: 11, maxLength: 11 })}
                                />
                              </div>
                              {errors.nextOfKinPhone && <small className="error-value">Please, provide a valid phone number</small>}
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group padright">
                              <label className="control-label" htmlFor="kin_email">Email </label>
                              <div className="input-group">
                                <div className="input-group-prepend">
                                  <div className="input-group-text"><i className="fa fa-envelope text-default"></i></div>
                                </div>
                                <input
                                  type="email"
                                  className="custom-form-control form-control"
                                  id="nextOfKinEmail"
                                  {...register('nextOfKinEmail', { pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i })}
                                />
                              </div>
                              {errors.nextOfKinEmail && <small className="error-value">Please, provide a valid email</small>}
                            </div>
                          </div>

                          <div className="form-group col-md-4 padleft">
                            <label className="control-label" htmlFor="gender">Gender<span id="compulsory">*</span></label>
                            <div className="input-group">
                              <div className="input-group-prepend">
                                <div className="input-group-text"><i className="fa fa-user text-default"></i></div>
                              </div>
                              <select
                                className="form-control custom-form-control"
                                id="nextOfKinGender"
                                {...register('nextOfKinGender', { required: true })}
                              >
                                <option value="">Select gender</option>
                                <option value="male">Male</option>
                                <option value="femail">Female</option>
                              </select>
                            </div>
                            {errors.nextOfKinGender && <small className="error-value">This field is required</small>}
                          </div>

                          <div className="col-md-4">
                            <div className="form-group padright">
                              <label className="control-label" htmlFor="kin_relationship">Relationship* </label>
                              <div className="input-group">
                                <div className="input-group-prepend">
                                  <div className="input-group-text"><i className="fa fa-link text-default"></i></div>
                                </div>
                                <input
                                  type="text"
                                  className="custom-form-control form-control"
                                  id="nextOfKinRelationship"
                                  {...register('nextOfKinRelationship', { required: true })}
                                />
                              </div>
                              {errors.nextOfKinRelationship && <small className="error-value">This field is required</small>}
                            </div>
                          </div>

                        </div>
                      </div>
                    </section>
                  </>}

                {
                  (ninValidated && !boxErrorMessage) &&
                  <section className={`card border-0  rounded ${!displayForm ? ' d-none' : 'd-block'}`} id="pers">
                    <div className="bg-white">

                      <div className="section-container">
                        <div className="section-item-container">
                        </div>
                        <div className="col-md-12 subheading text-white text-center card-header section-header-bg w-100 p-3 m-0">
                          <h1>State of Origin / Permanent Home Address</h1>
                        </div>
                        <div className="section-item-container">
                        </div>
                      </div>
                      <div className="row p-3">

                        <div className="form-group col-md-6 padright">
                          <label className="control-label" htmlFor="country">Nationality<span id="compulsory">*</span></label>
                          <div className="input-group">
                            <div className="input-group-prepend">
                              <div className="input-group-text"><i className="fa  fa-flag text-default"></i></div>
                            </div>
                            <select
                              className="form-control custom-form-control"
                              disabled
                              id="country"
                              // {...register('country', { required: true })}
                              value="410"
                            >
                              <option value="">Select Country</option>
                              <option value="410">Nigeria</option>

                            </select>
                          </div>
                          {errors.country && <small className="error-value">This field is required</small>}
                        </div>

                        <div className="form-group col-md-6 padleft">
                          <label className="control-label" htmlFor="state">State<span id="compulsory">*</span></label>
                          <div className="input-group">
                            <div className="input-group-prepend">
                              <div className="input-group-text"><i className="fa fa-map-marker text-default"></i></div>
                            </div>
                            <select
                              className="form-control custom-form-control"
                              id="stateOfPermanent"
                              {...register('stateOfPermanent', { required: true })}
                              onChange={changeFormData}
                            >
                              <option value="">Select state</option>
                              {
                                states && states[2]?.data?.map((state, index) => <option key={index} value={state.name}>{state.name}</option>)
                              }
                            </select>
                          </div>
                          {errors.stateOfPermanent && <small className="error-value">This field is required</small>}
                        </div>
                        <div className="for-group col-md-6 padright">
                          <label className="control-label" htmlFor="lga">LGA<span id="compulsory">*</span></label>
                          <div className="input-group">
                            <div className="input-group-prepend">
                              <div className="input-group-text"><i className="fa fa-map-marker text-default"></i></div>
                            </div>
                            <select
                              className="form-control custom-form-control"
                              // disabled
                              id="lgaOfPermanent"
                              {...register('lgaOfPermanent', { required: true })}
                            >
                              <option value="">Select LGA</option>
                              {
                                formData.stateOfPermanent &&

                                lgas[2]?.data?.filter(y => y.state_id === (states[2]?.data?.find(x => x?.name === formData?.stateOfPermanent))?.id).map((lga, index) => <option key={index} value={lga.name}>{lga.name}</option>)
                              }

                            </select>
                          </div>
                          {errors.lgaOfPermanent && <small className="error-value">This field is required</small>}
                        </div>
                        <div className="form-group col-md-6 padleft">
                          <label className="control-label" htmlFor="streetAddressOfPermanent">Street<span id="compulsory">*</span></label>
                          <div className="input-group">
                            <div className="input-group-prepend">
                              <div className="input-group-text"><i className="fa fa-map-marker text-default"></i></div>
                            </div>
                            <input
                              type="text"
                              className="custom-form-control form-control"
                              id="streetAddressOfPermanent"
                              {...register('streetAddressOfPermanent', { required: true })}
                            />
                          </div>
                          {errors.streetAddressOfPermanent && <small className="error-value">This field is required</small>}
                        </div>
                        <div className="col-md-6 padright">
                          <label className="control-label" htmlFor="mobile1_code">Contact Phone <span id="compulsory">*</span></label>
                          <div className="col-xs-12">
                            <div className="form-group col-xs-4">
                              <div className="input-group">
                                <div className="input-group-prepend">
                                  <div className="input-group-text"><i className="fa fa-phone text-default"></i></div>
                                </div>
                                <input
                                  type="number"
                                  className="custom-form-control form-control"
                                  id="contactPhone"
                                  {...register('contactPhone', { minLength: 11, maxLength: 11 })}
                                  min="0"
                                />
                              </div>
                              {errors.contactPhone && <small className="error-value">Please, provide a valid phone number</small>}
                            </div>
                          </div>
                        </div>

                        <div className="form-group col-md-6 padleft">
                          <label className="control-label" htmlFor="postal_address">Postal Address</label>
                          <div className="input-group">
                            <div className="input-group-prepend">
                              <div className="input-group-text"><i className="fa fa-address-book text-default"></i></div>
                            </div>
                            <input
                              type="text"
                              id="postalAddress"
                              className="custom-form-control form-control"
                              placeholder="Postal Address"
                              {...register('postalAddress')}
                            />
                          </div>
                          {errors.postalAddress && <small className="error-value">This field is required</small>}
                        </div>
                      </div>
                    </div>
                  </section>
                }

                {
                  (ninValidated && !boxErrorMessage) &&
                  <section className={`card border-0  rounded ${!displayForm ? ' d-none' : 'd-block'}`} id="resident">
                    <div className="bg-white">

                      <div className="section-container">
                        <div className="section-item-container">
                        </div>
                        <div className="col-md-12 subheading text-white text-center card-header section-header-bg">
                          <h1>Current Residential Address</h1>
                        </div>
                        <div className="section-item-container">
                        </div>
                      </div>
                      <div className="row p-3">
                        <div className="form-group col-md-6 padright">
                          <label className="control-label" htmlFor="state_of_residence">State of Residence<span id="compulsory">*</span></label>
                          <div className="input-group">
                            <div className="input-group-prepend">
                              <div className="input-group-text"><i className="fa fa-flag text-default"></i></div>
                            </div>
                            <select
                              className="form-control custom-form-control"
                              id="stateOfResidence"
                              {...register('stateOfResidence', { required: true })}
                              onChange={changeFormData}
                            >
                              <option value="">Select state</option>
                              {
                                states[2]?.data?.map((state, index) => <option key={index} value={state.name}>{state.name}</option>)
                              }
                            </select>
                          </div>
                          {errors.stateOfResidence && <small className="error-value">This field is required</small>}
                        </div>

                        <div className="form-group col-md-6 padleft">
                          <label className="control-label" htmlFor="lga_of_residence">LGA of Residence<span id="compulsory">*</span></label>
                          <div className="input-group">
                            <div className="input-group-prepend">
                              <div className="input-group-text"><i className="fa fa-map-marker"></i></div>
                            </div>
                            <select
                              className="form-control custom-form-control"
                              id="lgaOfResidence"
                              {...register('lgaOfResidence', { required: true })}
                            >
                              <option value="">Select LGA</option>
                              {
                                formData.stateOfResidence &&

                                lgas[2]?.data?.filter(y => y.state_id === (states[2]?.data?.find(x => x.name === formData?.stateOfResidence))?.id).map((lga, index) => <option key={index} value={lga.name}>{lga.name}</option>)
                              }
                            </select>
                          </div>
                          {errors.lgaOfResidence && <small className="error-value">This field is required</small>}
                        </div>

                        <div className="col-md-12">
                          <div className="form-group">
                            <label className="control-label" htmlFor="street_address">Street Address<span id="compulsory">*</span></label>
                            <div className="input-group">
                              <textarea
                                cols={30}
                                rows={4}
                                id="streetAddressOfResidence"
                                className="custom-form-control form-control"
                                {...register('streetAddressOfResidence', { required: true })}
                              >

                              </textarea>
                            </div>
                            {errors.streetAddressOfResidence && <small className="error-value">This field is required</small>}
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                }
                {
                  (ninValidated && !boxErrorMessage) && <>
                    <section className={`card border-0 rounded${(!ninValidated || boxErrorMessage) ? ' d-none' : ''} ${!displayForm ? ' d-none' : 'd-block'}`} id="doc">
                      <div className="bg-white">
                        <div className="section-container">
                          <div className="section-item-container">
                          </div>
                          <div className="col-md-12 w-100 subheading text-white text-center card-header section-header-bg">
                            <h1>SSCE Details</h1>
                          </div>
                          <div className="section-item-containers container">
                            {ssceFormRow.length < 2 && <div className='w-100 text-center'>
                              <button className="btn btn-primary mb-4 mt-4 fw-bold" onClick={(e) => handleAddFormRow(e)}>
                                <i className='fa fa-plus mr-3'></i>Add
                              </button>
                            </div>}
                            {ssceFormRow.length > 0 && (
                              <>
                                {ssceFormRow.map((field, index) => (

                                  <div key={index} className="row my-4">
                                    <div className="col-sm-3">
                                      <div className='form-group'>
                                        <label>Exam Type</label>
                                        <select className='form-control' onChange={(event) => handleFormRowInputChange(index, event)} name="examType" >

                                          {field.examType ? <option>{field.examType}</option> : <option>--Please choose exam type--</option>}
                                          <option>NECO</option>
                                          <option>WAEC</option>
                                          <option>GCE</option>
                                          <option>NABTEB</option>
                                        </select>
                                      </div>
                                    </div>

                                    <div className="col-sm-3">
                                      <div className='form-group'>
                                        <label>Exam Number</label>
                                        <input type="text" name="examNo" className='form-control' onChange={(event) => handleFormRowInputChange(index, event)} value={field.examNo} />
                                      </div>
                                    </div>

                                    <div className="col-sm-3">
                                      <div className='form-group'>
                                        <label>Exam Year</label>
                                        <select className='form-control' name="examYear" onChange={(event) => handleFormRowInputChange(index, event)}>
                                          {field.examYear ? <option>{field.examYear}</option> : <option>--Please choose year--</option>}
                                          <option>2023</option>
                                          <option>2022</option>
                                          <option>2021</option>
                                          <option>2020</option>
                                          <option>2019</option>
                                          <option>2018</option>
                                          <option>2017</option>
                                          <option>2016</option>
                                          <option>2015</option>
                                          <option>2014</option>
                                        </select>
                                      </div>
                                    </div>
                                    <div className="col-sm-3 mt-4">
                                      {index !== 0 && <button className="btn btn-primary" onClick={() => handleRemoveFormRow(index)} >remove </button>}
                                    </div>
                                  </div>))}
                              </>)}
                          </div>
                        </div>
                      </div>
                    </section>

                    <section className={`card border-0 rounded${(!ninValidated || boxErrorMessage) ? ' d-none' : ''} ${validateFormRow() === false && "d-none"} ${!displayForm ? ' d-none' : 'd-block'}`} id="doc">
                      <div className="bg-white">
                        <div className="section-container">
                          <div className="section-item-container">
                          </div>
                          <div className="col-md-12 w-100 subheading text-white text-center card-header section-header-bg">
                            <h1>Required Documents</h1>
                          </div>
                          <div className="section-item-container">
                          </div>
                        </div>
                        <div className="row m-0 p-0">
                          <div className="col-md-6 offset-md-3 p-3 bg-white">
                            Are you using two sittings for your ssce?
                            <label className='ml-3 mr-4'>
                              Yes <input type="radio" id="sittings" name="sittings" onChange={() => setSittings(2)}></input>
                            </label>
                            <label>
                              No <input type="radio" id="sittings" name="sittings" onChange={() => setSittings(1)}></input>
                            </label>
                            <div className="col-md-12 p-2">
                              {
                                getDocumentsUploadProgress() === 100 ? 'File attachment completed' : 'File attachment in progress'
                              }
                              <div className="progress">
                                <div
                                  className={`progress-bar ${getDocumentsUploadProgress() === 100 ? 'bg-success' : 'bg-warning'}`}
                                  role="progressbar"
                                  style={{ width: getDocumentsUploadProgress() + '%' }}
                                  aria-valuenow={getDocumentsUploadProgress()}
                                >
                                  {`${getDocumentsUploadProgress()}%`}
                                </div>
                              </div>
                            </div>

                            <div className="form-group col-md-12 padleft">
                              <small className="text-danger">NB: Maximum of two(2) OLevel sittings are allowed. Ensure you upload a well scanned document. A pooly scanned document with low resolution is not verifiable.</small>
                              <label className="control-label" htmlFor="gender">Choose a document type to upload(.jpg only)<span id="compulsory">*</span></label>
                              <div className="input-group">
                                <div className="input-group-prepend">
                                  <div className="input-group-text"><i className="fa fa-file text-default"></i></div>
                                </div>
                                <select
                                  className="form-control"
                                  value={currentDocument}
                                  onChange={changeCurrentDocument}
                                  disabled={sittings === 0}
                                >
                                  <option value=""> -- choose file type to upload -- </option>
                                  <optgroup label="Required Documents">
                                    <option value="birthCertificate">Birth Certificate/Age Declaration</option>
                                    <option value="indigneCerificate">LGA/ indigene certificate</option>
                                    {
                                      radioForm && radioForm === 'specialist' ? <option value="professionalCertificate">Professional Certificate</option> : ''
                                    }

                                  </optgroup>
                                  <optgroup label="SSCE Certificate">
                                    <option value="waec">WAEC</option>
                                    <option value="gce">GCE</option>
                                    <option value="neco">NECO</option>
                                    <option value="nabteb">NABTEB</option>
                                  </optgroup>
                                </select>
                              </div>
                              <small className="error-value">{documentError}</small>
                            </div>

                            <div className="form-group col-md-12 ">
                              {
                                currentDocument &&
                                <div className="image-upload-wrap w-100 animate__animated animate__fadeIn" {...getRootProps()}>
                                  <input className="file-upload-input" type='file' accept="image/*" {...getInputProps()} />
                                  <div className="drag-text">
                                    {
                                      isDragActive
                                        ? <h3 className="h33">Drop the file</h3>
                                        : <h3 className="h33">Click here to upload a file or Drag and drop a file</h3>
                                    }
                                  </div>
                                </div>
                              }
                            </div>

                            <div className="col-md-12">
                              {
                                Object.keys(documents).map((key: string, index: number) => {
                                  if (documents[key as keyof DocumentsType] !== '') {
                                    return (
                                      <span key={index} className="upload-pill animate__animated animate__zoomIn">
                                        <button onClick={() => removeDocument(key)} className="p-0 btn btn-success m-1">{documentsMapper(key)} <i className="fa fa-times fa-1x btn-progress m-0 text-white cancel"></i></button>
                                      </span>
                                    )
                                  }
                                  return null
                                })
                              }

                            </div>
                          </div>
                        </div>
                      </div>
                    </section>

                    <section className={'card border-0 d-none rounded'} id="doc">
                      <div className="bg-white d-none">
                        <div className="section-container">
                          <div className="section-item-container">
                          </div>
                          <div className="col-md-12 w-100 subheading text-white text-center card-header section-header-bg">
                            <h1>Verify Result</h1>
                          </div>
                          <div className="section-item-container">
                          </div>
                        </div>
                        <div className="row m-0 p-0">
                          <div className="col-md-6 offset-md-3 p-3 bg-white text-center">
                            <div className="card">
                              <div className="card">
                                <div className="card-header  text-white section-header-bg" >
                                  <i className="fa fa-info bg rounded p-1"></i> Input your 5 credit score
                                </div>
                                <div className="card-body p-2 h-5">
                                  <div className="form-group col-md-12 padleft">
                                    <label className="control-label" htmlFor="gender">OLevel number of sittings<span id="compulsory">*</span></label>
                                    <div className="input-group">
                                      <div className="input-group-prepend">
                                        <div className="input-group-text"><i className="fa fa-file text-default"></i></div>
                                      </div>
                                      <select className="form-control"
                                        onChange={(e) => setCurrentSitting(e.target.value)}
                                      >

                                        <option value="">--sittings--</option>
                                        <option value="firstSitting">1 Sitting</option>
                                        <option value="secondSitting">2 Sitting</option>
                                      </select>
                                    </div>
                                    <small className="error-value">{resultError}</small>
                                  </div>

                                  {ssceLoading && <div className='mt-4 text-danger'> Please wait while we verify your ssce result.</div>}
                                  {(currentSitting === 'firstSitting' || currentSitting === 'secondSitting') &&
                                    (<div className="form-group col-md-12 padleft">
                                      <span className={ninValidated ? 'btn btn-success' : 'btn btn-default'} onClick={generateOcr}>
                                        {ssce && <i className="fa fa-check text-light"></i>}
                                        {ssce ? ' Verified' : 'Verify'}
                                      </span>
                                      {ssceLoading && <div style={{ marginTop: 10 }} className="spinner-border spinner-border-sm"></div>}
                                    </div>)}

                                  <div className="form-group col-md-12 padleft d-none">
                                    <label className="control-label" htmlFor="gender">Examination Number of {currentSitting === 'firstSitting' ? '1st Sitting' : '2nd Sitting'}<span id="compulsory">*</span></label>
                                    <div className="input-group">
                                      <div className="input-group-prepend">
                                        <div className="input-group-text"><i className="fa fa-file text-default"></i></div>
                                      </div>
                                      <input
                                        type="text"
                                        id={currentSitting === 'firstSitting' ? 'firstSittingRegNo' : 'secondSittingRegNo'}
                                        name={currentSitting === 'firstSitting' ? 'firstSittingRegNo' : 'secondSittingRegNo'}
                                        className="custom-form-control form-control"
                                        placeholder="Examination Number"
                                        value={currentSitting === 'firstSitting' ? formData.firstSittingRegNo : formData.secondSittingRegNo}
                                        onChange={changeFormData}
                                      />
                                    </div>
                                    <small className="error-value">{examNoError}</small>
                                  </div>
                                  {/* {ssce && <p className='text-danger'>Incorrect verification of  result maybe due to poor document resolution, A minimum resolution of 200DPI is required. Kindly update your records in the fields bellow and click on the submit button.</p>}

                                                               {ssce?.collatedSSCE?.firstSitting && ssce?.collatedSSCE?.firstSitting?.length > 0 && ssce !== 'null' &&
                                                               <div>
                                                               <h4>First Sitting</h4>
                                                                <table className="table">
                                                                    <tbody>
                                                                    {ssce?.collatedSSCE?.firstSitting && ssce?.collatedSSCE?.firstSitting?.length > 0 && ssce?.collatedSSCE?.firstSitting?.map((res:any, index:number) => {
                                                                      // setValue("subject" + index, res.subject, { shouldValidate: true })
                                                                      // setValue("grade" + index, res.grade, { shouldValidate: true })
                                                                      return res.grade && <tr key={index}>
                                                                            <td>
                                                                                <input
                                                                                    type="text"
                                                                                    className="form-control"
                                                                                    defaultValue={res.subject}
                                                                                    name={`${res.subject}${index}`}
                                                                                    onChange={handleFirstSittingChange}
                                                                                >
                                                                                </input>
                                                                            </td>

                                                                            <td>
                                                                               <input
                                                                                type="text"
                                                                                className="form-control"
                                                                                defaultValue={res.grade}
                                                                                name={`${res.grade}${index}`}
                                                                                onChange={handleFirstSittingChange}
                                                                            >
                                                                            </input>
                                                                            </td>
                                                                        </tr>
                                                                    })}
                                                                    </tbody>
                                                                </table>
                                                              </div>
                                                            }

                                                              {ssce?.collatedSSCE?.secondSitting?.length > 0 && ssce !== 'null' &&
                                                              <div>
                                                                <h4>Second Sitting</h4>
                                                                <table className="table">
                                                                    <tbody>
                                                                    {ssce?.collatedSSCE?.secondSitting?.length > 0 && ssce?.collatedSSCE?.secondSitting?.map((res:any, index:number) => {
                                                                      // setValue("subject2" + index, res.subject, { shouldValidate: true })
                                                                      // setValue("grade2" + index, res.grade, { shouldValidate: true })
                                                                      return res.grade && <tr key={index}>
                                                                            <td>
                                                                                <input
                                                                                    type="text"
                                                                                    className="form-control"
                                                                                    defaultValue={res.subject}
                                                                                    name={`${res.subject}${index}`}
                                                                                    onChange={handleSecondSittingChange}
                                                                                >
                                                                                </input>
                                                                            </td>

                                                                            <td>
                                                                              <input
                                                                                type="text"
                                                                                className="form-control"
                                                                                defaultValue={res.grade}
                                                                                name={`${res.grade}${index}`}
                                                                                onChange={handleSecondSittingChange}
                                                                            >
                                                                            </input>
                                                                            </td>
                                                                        </tr>
                                                                    })}
                                                                    </tbody>
                                                                </table>
                                                                </div>
                                                            } */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>

                    <section className={`card border-0   rounded${(!ninValidated || boxErrorMessage) ? ' d-none' : ''} ${!displayForm ? ' d-none' : 'd-block'} ${validateFormRow() === false && "d-none"}`} id="doc">
                      <div className="bg-white">
                        <div className="section-container">
                          <div className="section-item-container">
                          </div>
                          <div className="col-md-12 w-100 subheading text-white text-center card-header section-header-bg">
                            <h1>Input Olevel Result</h1>
                          </div>
                          <div className="section-item-container">
                          </div>
                        </div>
                        <div className="row m-0 p-0">
                          <div className={[1, 0].includes(sittings) ? "col-md-6 p-3 bg-white text-center offset-md-3" : "col-md-6 p-3 bg-white text-center"}>

                            {/* <div className="col-md-6 p-3 bg-white text-center"> */}
                            <div>
                              <h4>First Sitting</h4>
                              <table className="table">
                                <tbody>
                                  <tr>
                                    <td>
                                      <select
                                        className="form-control"

                                        name="subject1"
                                        placeholder='subject 1'
                                        onChange={handleFirstSittingChange}
                                      >
                                        <option>--select subject--</option>
                                        {subArray.slice(0, 1).map((res: any, index: any) => {
                                          return <option key={index}>{res}</option>
                                        })}
                                      </select>
                                    </td>

                                    <td>
                                      <select
                                        className="form-control"
                                        placeholder='grade 1'
                                        name="grade1"
                                        onChange={handleFirstSittingChange}
                                      >
                                        <option>--select grade-</option>
                                        <option>A1</option>
                                        <option>B2</option>
                                        <option>B3</option>
                                        <option>C4</option>
                                        <option>C5</option>
                                        <option>C6</option>
                                        <option>D7</option>
                                        <option>E8</option>
                                        <option>F9</option>
                                      </select>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <select
                                        className="form-control"
                                        name="subject2"

                                        placeholder='subject 2'
                                        onLoad={handleFirstSittingChange}
                                      >
                                        <option>--select subject--</option>
                                        {subArray.slice(1, 2).map((res: any, index: any) => {
                                          return <option key={index}>{res}</option>
                                        })}
                                      </select>
                                    </td>

                                    <td>
                                      <select
                                        className="form-control"
                                        name="grade2"
                                        placeholder='grade 2'
                                        onChange={handleFirstSittingChange}
                                      >
                                        <option>--select grade-</option>
                                        <option>A1</option>
                                        <option>B2</option>
                                        <option>B3</option>
                                        <option>C4</option>
                                        <option>C5</option>
                                        <option>C6</option>
                                        <option>D7</option>
                                        <option>E8</option>
                                        <option>F9</option>
                                      </select>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <select
                                        className="form-control"
                                        name="subject3"
                                        placeholder='subject 3'
                                        onChange={handleFirstSittingChange}
                                      >
                                        <option>--select subject--</option>
                                        {subArray.map((res: any, index: any) => {
                                          return <option key={index}>{res}</option>
                                        })}
                                      </select>
                                    </td>

                                    <td>
                                      <select
                                        className="form-control"
                                        name="grade3"
                                        placeholder='grade 3'
                                        onChange={handleFirstSittingChange}
                                      >
                                        <option>--select grade-</option>
                                        <option>A1</option>
                                        <option>B2</option>
                                        <option>B3</option>
                                        <option>C4</option>
                                        <option>C5</option>
                                        <option>C6</option>
                                        <option>D7</option>
                                        <option>E8</option>
                                        <option>F9</option>
                                      </select>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <select
                                        className="form-control"
                                        name="subject4"
                                        placeholder='subject 4'
                                        onChange={handleFirstSittingChange}
                                      >
                                        <option>--select subject--</option>
                                        {subArray.map((res: any, index: any) => {
                                          return <option key={index}>{res}</option>
                                        })}
                                      </select>
                                    </td>

                                    <td>
                                      <select
                                        className="form-control"
                                        name="grade4"
                                        placeholder='grade 4'
                                        onChange={handleFirstSittingChange}
                                      >
                                        <option>--select grade-</option>
                                        <option>A1</option>
                                        <option>B2</option>
                                        <option>B3</option>
                                        <option>C4</option>
                                        <option>C5</option>
                                        <option>C6</option>
                                        <option>D7</option>
                                        <option>E8</option>
                                        <option>F9</option>
                                      </select>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <select
                                        className="form-control"
                                        name="subject5"
                                        placeholder='subject 5'
                                        onChange={handleFirstSittingChange}
                                      >
                                        <option>--select subject--</option>
                                        {subArray.map((res: any, index: any) => {
                                          return <option key={index}>{res}</option>
                                        })}
                                      </select>
                                    </td>

                                    <td>
                                      <select
                                        className="form-control"
                                        name="grade5"
                                        placeholder='grade 5'
                                        onChange={handleFirstSittingChange}
                                      >
                                        <option>--select grade-</option>
                                        <option>A1</option>
                                        <option>B2</option>
                                        <option>B3</option>
                                        <option>C4</option>
                                        <option>C5</option>
                                        <option>C6</option>
                                        <option>D7</option>
                                        <option>E8</option>
                                        <option>F9</option>
                                      </select>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <select
                                        className="form-control"
                                        name="subject6"
                                        placeholder='subject 6'
                                        onChange={handleFirstSittingChange}
                                      >
                                        <option>--select subject--</option>
                                        {subArray.map((res: any, index: any) => {
                                          return <option key={index}>{res}</option>
                                        })}
                                      </select>
                                    </td>

                                    <td>
                                      <select
                                        className="form-control"
                                        name="grade6"
                                        placeholder='grade 6'
                                        onChange={handleFirstSittingChange}
                                      >
                                        <option>--select grade-</option>
                                        <option>A1</option>
                                        <option>B2</option>
                                        <option>B3</option>
                                        <option>C4</option>
                                        <option>C5</option>
                                        <option>C6</option>
                                        <option>D7</option>
                                        <option>E8</option>
                                        <option>F9</option>
                                      </select>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <select
                                        className="form-control"
                                        name="subject7"
                                        placeholder='subject 7'
                                        onChange={handleFirstSittingChange}
                                      >
                                        <option>--select subject--</option>
                                        {subArray.map((res: any, index: any) => {
                                          return <option key={index}>{res}</option>
                                        })}
                                      </select>
                                    </td>

                                    <td>
                                      <select
                                        className="form-control"
                                        name="grade7"
                                        placeholder='grade 7'
                                        onChange={handleFirstSittingChange}
                                      >
                                        <option>--select grade-</option>
                                        <option>A1</option>
                                        <option>B2</option>
                                        <option>B3</option>
                                        <option>C4</option>
                                        <option>C5</option>
                                        <option>C6</option>
                                        <option>D7</option>
                                        <option>E8</option>
                                        <option>F9</option>
                                      </select>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <select
                                        className="form-control"
                                        name="subject8"
                                        placeholder='subject 8'
                                        onChange={handleFirstSittingChange}
                                      >
                                        <option>--select subject--</option>
                                        {subArray.map((res: any, index: any) => {
                                          return <option key={index}>{res}</option>
                                        })}
                                      </select>
                                    </td>

                                    <td>
                                      <select
                                        className="form-control"
                                        placeholder='grade 8'
                                        name="grade8"
                                        onChange={handleFirstSittingChange}
                                      >
                                        <option>--select grade-</option>
                                        <option>A1</option>
                                        <option>B2</option>
                                        <option>B3</option>
                                        <option>C4</option>
                                        <option>C5</option>
                                        <option>C6</option>
                                        <option>D7</option>
                                        <option>E8</option>
                                        <option>F9</option>
                                      </select>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <select
                                        className="form-control"
                                        name="subject9"
                                        placeholder='subject 9'
                                        onChange={handleFirstSittingChange}
                                      >
                                        <option>--select subject--</option>
                                        {subArray.map((res: any, index: any) => {
                                          return <option key={index}>{res}</option>
                                        })}
                                      </select>
                                    </td>

                                    <td>
                                      <select
                                        className="form-control"
                                        name="grade9"
                                        placeholder='grade 9'
                                        onChange={handleFirstSittingChange}
                                      >
                                        <option>--select grade-</option>
                                        <option>A1</option>
                                        <option>B2</option>
                                        <option>B3</option>
                                        <option>C4</option>
                                        <option>C5</option>
                                        <option>C6</option>
                                        <option>D7</option>
                                        <option>E8</option>
                                        <option>F9</option>
                                      </select>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>

                          <div className={sittings === 2 ? "col-md-6 p-3 bg-white text-center" : "d-none col-md-6 p-3 bg-white text-center"}>
                            <div className="card">
                              <div className="card">
                                <div>
                                  <h4>Second Sitting</h4>
                                  <table className="table">
                                    <tbody>
                                      <tr>
                                        <td>
                                          <select
                                            className="form-control"
                                            name="subject1"

                                            placeholder='subject 1'
                                            onChange={handleSecondSittingChange}
                                          >
                                            <option>--select subject--</option>
                                            {subArray.slice(0, 1).map((res: any, index: any) => {
                                              return <option key={index}>{res}</option>
                                            })}
                                          </select>
                                        </td>

                                        <td>
                                          <select
                                            className="form-control"
                                            placeholder='grade 1'
                                            name="grade1"
                                            onChange={handleSecondSittingChange}
                                          >
                                            <option>--select grade-</option>
                                            <option>A1</option>
                                            <option>B2</option>
                                            <option>B3</option>
                                            <option>C4</option>
                                            <option>C5</option>
                                            <option>C6</option>
                                            <option>D7</option>
                                            <option>E8</option>
                                            <option>F9</option>
                                          </select>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          <select
                                            className="form-control"
                                            name="subject2"
                                            placeholder='subject 2'

                                            onChange={handleSecondSittingChange}
                                          >
                                            <option>--select subject--</option>
                                            {subArray.slice(1, 2).map((res: any, index: any) => {
                                              return <option key={index}>{res}</option>
                                            })}
                                          </select>
                                        </td>

                                        <td>
                                          <select
                                            className="form-control"
                                            name="grade2"
                                            placeholder='grade 2'
                                            onChange={handleSecondSittingChange}
                                          >
                                            <option>--select grade-</option>
                                            <option>A1</option>
                                            <option>B2</option>
                                            <option>B3</option>
                                            <option>C4</option>
                                            <option>C5</option>
                                            <option>C6</option>
                                            <option>D7</option>
                                            <option>E8</option>
                                            <option>F9</option>
                                          </select>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          <select
                                            className="form-control"
                                            name="subject3"
                                            placeholder='subject 3'
                                            onChange={handleSecondSittingChange}
                                          >
                                            <option>--select subject--</option>
                                            {subArray.map((res: any, index: any) => {
                                              return <option key={index}>{res}</option>
                                            })}
                                          </select>
                                        </td>

                                        <td>
                                          <select
                                            className="form-control"
                                            name="grade3"
                                            placeholder='grade 3'
                                            onChange={handleSecondSittingChange}
                                          >
                                            <option>--select grade-</option>
                                            <option>A1</option>
                                            <option>B2</option>
                                            <option>B3</option>
                                            <option>C4</option>
                                            <option>C5</option>
                                            <option>C6</option>
                                            <option>D7</option>
                                            <option>E8</option>
                                            <option>F9</option>
                                          </select>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          <select
                                            className="form-control"
                                            name="subject4"
                                            placeholder='subject 4'
                                            onChange={handleSecondSittingChange}
                                          >
                                            <option>--select subject--</option>
                                            {subArray.map((res: any, index: any) => {
                                              return <option key={index}>{res}</option>
                                            })}
                                          </select>
                                        </td>

                                        <td>
                                          <select
                                            className="form-control"
                                            name="grade4"
                                            placeholder='grade 4'
                                            onChange={handleSecondSittingChange}
                                          >
                                            <option>--select grade-</option>
                                            <option>A1</option>
                                            <option>B2</option>
                                            <option>B3</option>
                                            <option>C4</option>
                                            <option>C5</option>
                                            <option>C6</option>
                                            <option>D7</option>
                                            <option>E8</option>
                                            <option>F9</option>
                                          </select>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          <select
                                            className="form-control"
                                            name="subject5"
                                            placeholder='subject 5'
                                            onChange={handleSecondSittingChange}
                                          >
                                            <option>--select subject--</option>
                                            {subArray.map((res: any, index: any) => {
                                              return <option key={index}>{res}</option>
                                            })}
                                          </select>
                                        </td>

                                        <td>
                                          <select
                                            className="form-control"
                                            name="grade5"
                                            placeholder='grade 5'
                                            onChange={handleSecondSittingChange}
                                          >
                                            <option>--select grade-</option>
                                            <option>A1</option>
                                            <option>B2</option>
                                            <option>B3</option>
                                            <option>C4</option>
                                            <option>C5</option>
                                            <option>C6</option>
                                            <option>D7</option>
                                            <option>E8</option>
                                            <option>F9</option>
                                          </select>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          <select
                                            className="form-control"
                                            name="subject6"
                                            placeholder='subject 6'
                                            onChange={handleSecondSittingChange}
                                          >
                                            <option>--select subject--</option>
                                            {subArray.map((res: any, index: any) => {
                                              return <option key={index}>{res}</option>
                                            })}
                                          </select>
                                        </td>

                                        <td>
                                          <select
                                            className="form-control"
                                            name="grade6"
                                            placeholder='grade 6'
                                            onChange={handleSecondSittingChange}
                                          >
                                            <option>--select grade-</option>
                                            <option>A1</option>
                                            <option>B2</option>
                                            <option>B3</option>
                                            <option>C4</option>
                                            <option>C5</option>
                                            <option>C6</option>
                                            <option>D7</option>
                                            <option>E8</option>
                                            <option>F9</option>
                                          </select>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          <select
                                            className="form-control"
                                            name="subject7"
                                            placeholder='subject 7'
                                            onChange={handleSecondSittingChange}
                                          >
                                            <option>--select subject--</option>
                                            {subArray.map((res: any, index: any) => {
                                              return <option key={index}>{res}</option>
                                            })}
                                          </select>
                                        </td>

                                        <td>
                                          <select
                                            className="form-control"
                                            name="grade7"
                                            placeholder='grade 7'
                                            onChange={handleSecondSittingChange}
                                          >
                                            <option>--select grade-</option>
                                            <option>A1</option>
                                            <option>B2</option>
                                            <option>B3</option>
                                            <option>C4</option>
                                            <option>C5</option>
                                            <option>C6</option>
                                            <option>D7</option>
                                            <option>E8</option>
                                            <option>F9</option>
                                          </select>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          <select
                                            className="form-control"
                                            name="subject8"
                                            placeholder='subject 8'
                                            onChange={handleSecondSittingChange}
                                          >
                                            <option>--select subject--</option>
                                            {subArray.map((res: any, index: any) => {
                                              return <option key={index}>{res}</option>
                                            })}
                                          </select>
                                        </td>

                                        <td>
                                          <select
                                            className="form-control"
                                            placeholder='grade 8'
                                            name="grade8"
                                            onChange={handleSecondSittingChange}
                                          >
                                            <option>--select grade-</option>
                                            <option>A1</option>
                                            <option>B2</option>
                                            <option>B3</option>
                                            <option>C4</option>
                                            <option>C5</option>
                                            <option>C6</option>
                                            <option>D7</option>
                                            <option>E8</option>
                                            <option>F9</option>
                                          </select>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          <select
                                            className="form-control"
                                            name="subject9"
                                            placeholder='subject 9'
                                            onChange={handleSecondSittingChange}
                                          >
                                            <option>--select subject--</option>
                                            {subArray.map((res: any, index: any) => {
                                              return <option key={index}>{res}</option>
                                            })}
                                          </select>
                                        </td>

                                        <td>
                                          <select
                                            className="form-control"
                                            name="grade9"
                                            placeholder='grade 9'
                                            onChange={handleSecondSittingChange}
                                          >
                                            <option>--select grade-</option>
                                            <option>A1</option>
                                            <option>B2</option>
                                            <option>B3</option>
                                            <option>C4</option>
                                            <option>C5</option>
                                            <option>C6</option>
                                            <option>D7</option>
                                            <option>E8</option>
                                            <option>F9</option>
                                          </select>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>

                        </div>
                      </div>
                    </section>

                    <div className={`card text-center  text-dark concent p-3${(!ninValidated || boxErrorMessage) ? ' d-none' : ''} ${!displayForm ? ' d-none' : 'd-block'}`}>
                      <div className="font-weight-bold text-danger alert alert-danger m-1">By submission, you have agreed that all documents and information given are complete and accurate, false information may lead to automatic disqualification
                      </div>
                      <div className="text-success alert alert-success font-weight-bolder m-1">
                        <input onChange={changeConsent} type="checkbox" className="checkb" />
                        &nbsp;&nbsp;&nbsp;I consent that every information provided on this form is true</div>
                    </div>

                    <div className={`border-0 offset-2 p-4${(!ninValidated || boxErrorMessage) ? ' d-none' : ''} ${!displayForm ? ' d-none' : 'd-block'}`}>
                      <button disabled={!consent || loading.submit} type="submit" className="btn btn-block w-75 shadow h-50 rounded btn-larg submit-btn-bg" >
                        <i className="fa fa-paper-plane rounded p-1"></i>  Submit {loading.submit && <div className="spinner-border spinner-border-sm"></div>}
                      </button>
                    </div>
                    <button id="myBtn" title="Go to top" className={`btn btn-dark to-top btn-progress${(!ninValidated || boxErrorMessage) ? ' d-none' : ''} ${!displayForm ? ' d-none' : 'd-block'}`}><i className="fa fa-caret-up fa-x5"></i></button>
                  </>}
              </>
            }

          </form>

        </main>
      </div>
      <Loader1 show={loading.step1}></Loader1>
      <Loader2 show={loading.step2}></Loader2>
    </Fragment>
  )
}

export default Application
