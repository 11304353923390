import React, { FC, useState } from 'react'
import Table from 'react-bootstrap/esm/Table'
import { useSelector } from 'react-redux'
import { RegisterHeader } from '../../../Components/Index'
import "./PrintScreenStatus.scss"
import { toast, Toaster } from 'react-hot-toast';
import applicantsService from '../../../../../Services/applicantsService'
import { useForm } from 'react-hook-form'

interface formValues {
    nin: number
}
const PhysicalScreeningSlip: FC = () => {
  const admission = useSelector((state: any) => state.admission)

  const [state, setstate] = useState({ photo: "", firstname: "", surname: "", middlename: "", nimc: { nin: "" }, pScreen: { metadata: { createdAt: 0 }, scheduleSchema: { nin: "", "pscreen-date": "", "pscreen-time": "", "pscreen-venue": "", "PSCREEN-DATE": "", "PSCREEN-TIME": "", "PSCREEN-VENUE": "", NIN: "" } } })

  const [loadingReq, setLoadingReq] = useState(false)
  const [successReq, setSuccessReq] = useState(false)
  const [qRcode, setQRCode] = useState('')

  const { handleSubmit, register, formState: { errors } } = useForm<formValues>()
  const onSubmit = handleSubmit((data: { [x: string]: any }) => processData(data))
  const processData = async (data: any) => {
    setLoadingReq(true)
    // console.log(data)
    setSuccessReq(false)
    await applicantsService.pscreeningSlip(data, admission?.admissionSettings?.admissionSettings?.activeApplicationId).then(
      (res) => {
        console.log("pscreening Schedule slip", res)

        setQRCode(res.data.authQR)

        if (!res.data.dataset.workflow.pScreen) {
          setSuccessReq(false)
          setLoadingReq(false)
          toast.error("Applicant has not been screened", { duration: 20000, className: 'bg-danger text-white' });
        } else {
          setstate({ ...res.data.dataset.formData, nimc: res.data.dataset.nimc, pScreen: res.data.dataset.workflow.pScreen, photo: res.data.photo })
          setSuccessReq(true)
          setLoadingReq(false)
        }
      }, (error) => {
        setLoadingReq(false)
        setSuccessReq(false)
        console.log("error 090", error.response)
        console.log("error 091", error)
        toast.error(error.response.data.message, { duration: 20000, className: 'bg-danger text-white' });
      })
  }

  return (
        <React.Fragment>
            <RegisterHeader />
            {!successReq && <div className="py-5 px-2 pscreening">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-7">
                            <div className="card border-0 shadow-sm">
                                <div className="card-header section-header-bg text-center">
                                    Print/Reprint Physical Screening Slip
                                </div>
                                <div className="card-body">
                                    <form onSubmit={onSubmit}>
                                        <div className="form-group">
                                            <label> National Identity Number (NIN)</label>
                                            <input className="form-control" {...register("nin", { required: 'field cannot be empty' })}></input>
                                            <div className="register--error text-danger"> {errors.nin && errors.nin.message}</div>
                                        </div>
                                        <div className="form-group text-center">
                                            {!loadingReq && <button className="btn btn-warning text-dark px-5 font-weight-bold">Print Slip</button>}
                                            {loadingReq && <button className="btn btn-dark text-dark px-5 font-weight-bold disable">
                                                Please wait... <span className='spinner-border spinner-border-sm'></span></button>}
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>}

            {successReq && <div className='pSchedule'>
                <div className="cbt-details my-5">
                    <div className="">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-8 offset-md-2">
                                    <div className="card bg-white p-2">
                                        <div className="table-responsive">
                                            <Table bordered>
                                                <tbody>
                                                    <tr>
                                                        <td colSpan={2} className="p-0">
                                                            <div className="card-header text-center text-dark card-status-header px-5">
                                                                <div className="row">
                                                                    <div className='col'>
                                                                        <div className='row'>
                                                                            <div className="col">
                                                                                <img className="img-responsive rounded-circle" width='50' height='50' src='psc_logo.png' />
                                                                            </div>
                                                                            <div className="col">
                                                                                <img className="img-responsive" width='50' height='50' src='police_logo.png' />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-9 col">
                                                                        <div className='mb-0 pb-0'><h1 className="text-light font-weight-bolder cbt-title fs-1">2022 Nigeria Police Force Recruitment</h1></div>

                                                                    </div>
                                                                </div>
                                                                <div className='mt-0 pt-0'><h5 className="text-light ">{admission?.dataset?.year} Candidate Physical Screening Schedule Slip</h5></div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan={2} className="text-center">
                                                            <img className="img-responsive" width='120' height='120' src={`data:image/png;base64, ${state.photo}`} />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan={2}>
                                                            <div className="d-inline justify-content-center py-4">
                                                                <div className="text-secondary font-weight-bolder fs-5 text-center text-name">{state.surname} {state.firstname} {state.middlename}</div>
                                                                <div className="text-secondary font-weight-bolder fs-5 text-center">NIN: {state.nimc.nin}</div>
                                                                <div className="text-secondary fw-bolder"></div>
                                                            </div>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td className="text-center"><b>Physical Screening Date:</b></td>
                                                        <td>{state.pScreen.scheduleSchema["pscreen-date"] || state.pScreen.scheduleSchema["PSCREEN-DATE"]}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="text-center"><b>Physical Screening Time:</b></td>
                                                        <td>{state.pScreen.scheduleSchema["pscreen-time"] || state.pScreen.scheduleSchema["PSCREEN-TIME"]}am</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="text-center"><b>Physical Screening Venue:</b></td>
                                                        <td>{state.pScreen.scheduleSchema["pscreen-venue"] || state.pScreen.scheduleSchema["PSCREEN-VENUE"] }</td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan={2} className="text-center mt-4">Please come along with a copy of this slip during the physical screening exercise.</td>
                                                    </tr>
                                                    <tr className=' '>
                                                    <td colSpan={2} className="text-center"><img className="img-responsive" src={`data:image/png;base64, ${qRcode}`} width={200} height={200} alt='qrcode' /></td>
                                                    </tr>

                                                    <tr>
                                                        <td className="text-center close-col" colSpan={2}>
                                                            <div className="pcolor1 px-5">
                                                                <div className="section-color-tage1"></div>
                                                                <div className="section-color-tage2"></div>
                                                                <div className="section-color-tage3"></div>
                                                                <div className="section-color-tage4"></div>
                                                                <div className="section-color-tage5"></div>
                                                            </div>
                                                        </td>
                                                    </tr>

                                                </tbody>
                                            </Table>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='pt-3 pb-5 justify-content-center d-flex'>
                            <button className="btn w-25 btn--default btn-block" onClick={() => window.print()}>Print</button>
                        </div>
                    </div>

                </div>
            </div>}
            <Toaster></Toaster>
        </React.Fragment>
  )
}

export default PhysicalScreeningSlip
