import React from 'react'
import Footer from '../../../../../Layout/Footer'
import { RegisterHeader } from '../../../Components/Index'

const ReportWrongScheduleMedical = () => {
  return (
    <React.Fragment>
      <RegisterHeader/>
          <div className="py-5 px-2  d-flex align-items-center">
              <div className="container">
                  <div className="card">
                      <div className="card-body">
                      Report Wrong Schedule Medical
                      </div>
                  </div>
              </div>
          </div>
      <Footer/>
  </React.Fragment>
  )
}

export default ReportWrongScheduleMedical
