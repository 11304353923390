import React from 'react'
import './RegisterFooter.scss'
const RegisterFooter = () => {
  return (
        <footer>
            <div className="pcolorFooter">
                    <div className="section-color-tage1"></div>
                    <div className="section-color-tage2"></div>
                    <div className="section-color-tage3"></div>
                </div>
            <div className="reg-footer">
                <div className="footer-credit pb-3">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6 text-center text-md-left text-white">
                                &copy; Nigeria Police Force 2021 - All Right Reserved.
                            </div>
                            <div className="col-md-6 text-center text-md-right text-white">
                                 <span className="reg-pwrd">Powered by : <span className="reg-jkk">Department of Training and Development</span></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
       </footer>
  )
}
export default RegisterFooter
