import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
// eslint-disable-next-line no-unused-vars
import toast, { Toaster } from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'
import { Loader } from '../../../../Components'
// eslint-disable-next-line no-unused-vars
import userService from '../../../../Services/userService'
import { RegisterFooter, RegisterHeader } from '../../Components/Index'
import './RegStatusVerification.scss'
// import MD5 from 'crypto-js/md5'
import { NinValidator } from '../../../../Helpers'
import { USER_FAILURE, USER_SUCCESS } from '../../../../Constants/UserConstants'
import { useDispatch } from 'react-redux'

interface formValues {
  nin: string;
}
const RegStatusVerification = () => {
  const { register, handleSubmit, formState: { errors } } = useForm<formValues>()
  const history = useNavigate()
  const [loader, setLoader] = useState(false)
  const dispatcher = useDispatch()

  const onSubmit = handleSubmit((data:any) => {
    setLoader(true)

    switch (true) {
      case NinValidator(data.refId):
        SlipValidation.ninValidation(data.refId)
        break
      default:
        SlipValidation.refValidation(data.refId)
    }
  }
  )

  const SlipValidation = {
    ninValidation: (data:string) => {
      userService.getDataAx(data).then((res:any) => {
        // console.log("AXXXXXXXXXXXX", res.data)
        if (res.data.nin) {
          dispatcher({ type: USER_SUCCESS, data: res.data })
          history('/status/' + res.data.nin)
        } else {
          setLoader(false)
          toast.error("NIN doesn't exist", { duration: 4000 })
        }
      }, (error: any) => {
        console.log(error.message)
        dispatcher({ type: USER_FAILURE, data: error.message })
        setLoader(false)
        toast.error(error.message, { duration: 4000 })
      })
    },

    refValidation: (hashRef:any) => {
      userService.getDataAx(hashRef).then((res:any) => {
        // console.log("AXXXXXXXXXXXX", res)
        if (res.data.nin) {
          dispatcher({ type: USER_SUCCESS, data: res.data })
          history('/status/' + res.data.nin)
        } else {
          setLoader(false)
          toast.error("Reference id doesn't exist", { duration: 4000 })
        }
      }, (error:any) => {
        console.log('Alas error:', error.message)
        dispatcher({ type: USER_SUCCESS, data: error.message })
        setLoader(false)
        toast.error(error.message, { duration: 4000 })
      })
    }
  }

  return (
    <div className="slip-confirmation h-100">
      <RegisterHeader></RegisterHeader>
            <div className="register-inner bg-light">
                 <div className="row h-100">
                   <div className="col-md-4 offset-md-4 my-auto">
                   <div className="card mt-5">
                    <div className="card-header text-center bg-white border-0">
                        <div className="c-header-crib-2">
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                       <h5>Check 2021 Application Status</h5>
                        <div className="c-header-crib">
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                    </div>
                    <div className="card-body">
                      <form onSubmit={onSubmit}>
                        <div className="form-group">
                          <label>National Identity No (NIN)</label>
                              <input className="form-control" {...register('nin', { required: 'field cannot be empty' })} placeholder={ 'please input your NIN only' } ></input>
                              <div className="register--error text-danger">
                              {errors.nin && errors.nin.message}
                          </div>
                        </div>
                        <div className="form-group text-center">
                        <button className="btn btn-outline-success"> Check Status </button>
                        </div>
                      </form>
                    </div>
                </div>
              </div>
            </div>
        </div>
      <RegisterFooter></RegisterFooter>
      <Loader show={loader}/>
      <Toaster/>
    </div>
  )
}

export default RegStatusVerification
