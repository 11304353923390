import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import './RegisterHeader.scss'
import userService from '../../../../../Services/userService'
import Cachedfunction from '../../../../../Helpers/cachedfunction'

const RegisterHeader = () => {
  const [resData, setResData] = useState<any>()
  const MenuController = async () => {
    localStorage.removeItem('MENU_COMMAND')
    await userService.getMenuCommand().then(
      response => {
        const res = response.data
        const data = res
        Cachedfunction(data)
        setResData(res)
      }
    ).catch(error => {
      console.log(error)
    }
    )
  }
  const checkTTLAndSchedule = () => {
    const storedItem = localStorage.getItem('MENU_COMMAND');

    if (storedItem) {
      const { expirationTime } = JSON.parse(storedItem);
      //   console.log(expirationTime)
      const date = new Date(expirationTime)
      //   console.log(date)
      if (expirationTime && date <= new Date()) {
        // TTL expired, remove data and refetch after a delay
        setTimeout(MenuController, 5000); // Example: 5-second delay
      }
    }
  };

  useEffect(() => {
    const commandss = localStorage.getItem('MENU_COMMAND')
    if (commandss !== null) {
      const { data } = JSON.parse(commandss)
      setResData(data)
    } else {
      MenuController()
    }
    checkTTLAndSchedule()
  }, [])
  return (
        <header className="register-header shadow">
            <div className="ost-multi-header">
                <div className="topnavs">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6 text-white text-md-left"> <i className='fa fa-phone'></i> 08069794453 | 09060483893 </div>
                            <div className="col-md-6 text-white text-md-right"> <i className='fa fa-envelope'></i>  support@policerecruitment.gov.ng </div>
                        </div>
                    </div>
                </div>

                <nav className="navbar navbar-expand-lg navbar-light bg-white">
                    <div className="container">
                        <div className="col-0 offset-sm-0">
                            <Link className="navbar-brand w-50" to="/">
                                <img src="psc1_logo.png" alt="Police Service Commission" className="logo" />
                            </Link>
                        </div>
                        <button className="navbar-toggler p-1 m-0" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                            {/* <span className="navbar-toggler-icon"></span> */}
                            <i className='fa fa-bars fa-2x text-dark'></i>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarNav">
                            <ul className="navbar-nav ml-auto">
                                <li className="nav-item active">
                                    <a className="nav-link" href="https://apply.policerecruitment.gov.ng">Home <span className="sr-only">(current)</span></a>
                                </li>

                                {<li className="nav-item dropdown">
                                    <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        Registration
                                    </a>
                                    <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                        {
                                            resData?.registration.switch === 1 && <Link className="dropdown-item" to="/apply">Apply for Recruitment</Link>
                                        }

                                        { resData?.registration.switch === 1 && <Link className="dropdown-item" to="/guideline">Requirement & Instruction</Link> }
                                        { resData?.registration.switch === 1 && <Link className="dropdown-item" to="/print-slip">Print Slip</Link> }

                                        <a className="dropdown-item" href="/media/GUARANTOR-FORM-PSC.pdf" target="_blank" rel="noreferrer">Download Guarantor Form</a>
                                    </div>
                                </li>}
                                        {
                                            resData?.pscreen.switch === 1 && <li className="nav-item dropdown">
                                    <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        Physical Screening
                                    </a>
                                    <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                    <Link className="dropdown-item" to="/print-screen-status">Print Screen Schedule</Link>
                                        <Link className="dropdown-item" to="/physical-screening-acknowlegement">Print Screen Acknowledgment</Link>
                                        <Link className="dropdown-item" to="/status-upload-request">Request for Screened Upload</Link>
                                    </div>
                                </li>
                                        }
{
    resData?.aptitude.switch === 1 && <li className="nav-item dropdown">
                                    <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        Aptitude/Interview
                                    </a>
                                    <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                        <Link className="dropdown-item" to="/print-aptitude-slip">Print General-Duty Slip</Link>
                                        <Link className="dropdown-item" to="/print-speciality-slip">Print Specialist Interview Slip</Link>
                                        <Link className="dropdown-item" to="/report-wrong-schedule">Report Wrong Schedule</Link>
                                    </div>
                                </li>

}
                                {
                                    resData?.medical.switch === 1 && <li className="nav-item dropdown">
                                    <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        Medical
                                    </a>
                                    <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                        <Link className="dropdown-item" to="/medical-print-slip">Print Medical Slip</Link>
                                        <Link className="dropdown-item" to="/status-nin-medical">Medical Status</Link>
                                        <Link className="dropdown-item" to="/report-wrong-schedule-medical">Report Wrong Schedule</Link>
                                    </div>
                                </li>
                                }

{
    resData?.institution.switch === 1 && <li className="nav-item dropdown">
                                    <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        Recruit & Institution
                                    </a>
                                    <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                        <Link className="dropdown-item" to="/institution-print-slip">Print Institution Slip</Link>
                                        <Link className="dropdown-item" to="/download-shortlisted">Download Shortlisted</Link>
                                        <Link className="dropdown-item" to="/report-wrong-schedule-admission">Report Wrong Schedule</Link>
                                        <Link className="dropdown-item" to="/report-rejection">Report Rejection</Link>
                                    </div>
                                </li>
}

                            </ul>
                        </div>

                        <div className="col-2 offset-4">
                            <Link className="navbar-brand w-50" to="/">
                                <img src="police_logo.png" alt="Police Service Commission" className="logo" />
                            </Link>
                        </div>
                    </div>
                </nav>

                <div className="pcolorFooter ">
                    <div className="section-color-tage1"></div>
                    <div className="section-color-tage2"></div>
                    <div className="section-color-tage3"></div>
                </div>
            </div>
        </header>
  )
}

export default RegisterHeader
