import React from 'react'
import "./MentainanceMode.scss"
import logo from "../../../../Assets/images/maintenance.png"

const MentainanceMode = () => {
  return (
        <div className='mentainanceMode'>
            <main>
            <div className="container py-4">
                <header className="pb-3 border-bottom">
                    <div className="row">
                        <a href="/" className="d-flex align-items-center text-dark text-decoration-none">
                            <img src="logo.png" alt="Responsive image" height="80" width="auto"/>
                        </a>
                    </div>
                </header>
                <div className="row mb-4">
                    <div className="col-4 section-color-tage1">&nbsp;</div>
                    <div className="col-4 section-color-tage2">&nbsp;</div>
                    <div className="col-4 section-color-tage3">&nbsp;</div>
                </div>

                <div className="p-5 mb-4 bg-light rounded-3">
                    <div className="container-fluid py-5">
                        <h1 className="fw-bold" >
                            <img src={logo} width="10%"/>
                        </h1>
                        <p className="col-md-8 fs-4">Our website is going through a maintenance and will be available soon.
                        We request you to visit later or talk to our representative line</p>
                        <button className="btn btn-primary btn-lg" type="button">08100004507</button>
                    </div>
                </div>

                <footer className="mt-4 text-muted border-top">
                <div className="row pb-3">
                    <div className="col-4 section-color-tage1">&nbsp;</div>
                    <div className="col-4 section-color-tage2">&nbsp;</div>
                    <div className="col-4 section-color-tage3">&nbsp;</div>
                </div>
                &copy; Nigeria Police Force {new Date().getFullYear()} - All Right Reserved.
                </footer>
            </div>
            </main>

        </div>
  )
}

export default MentainanceMode
